import React from 'react'
import {ListDealCard} from "./ListDealCard";
import {useListDealsContext} from "../../contexts/ListDealsContext";
import {useMediaQuery} from "react-responsive";
import {mediaQueryForMobile} from "../HomePage/HomePage";
import {ListDealMobileCard} from "./ListDealMobileCard";
import {useModalContext} from "../../contexts/ModalContext";
import CustomInfiniteScroll from "../../components/CustomInfiniteScroll";

export const ListDealsList = ( { listDealsState } ) => {
    const isTabletOrMobile = useMediaQuery({ query: mediaQueryForMobile })
    const { isDealModalOpen } = useModalContext()

    const selectedDealId = isDealModalOpen

    const {
        fetchListDealsResponse,
        fetchNextDeals,
        shouldFetchNextDeals
    } = useListDealsContext()

    return (
        <div className={'ListDealsList'}>
            <CustomInfiniteScroll
                useWindow={false}
                pageStart={0}
                loadMore={() => shouldFetchNextDeals() && fetchNextDeals()}
                hasMore={listDealsState.has_more}
                className={'ListDealsList_container'}
            >
                {
                    listDealsState?.results?.map((deal) => {
                        const isSelected = Boolean(selectedDealId === deal.id)

                        if (isTabletOrMobile) {
                            return <ListDealMobileCard
                                deal={deal}
                                key={deal.id}
                                isSelected={isSelected}
                            />
                        }

                        return <ListDealCard
                            deal={deal}
                            key={deal.id}
                            isSelected={isSelected}
                        />
                    })
                }
            </CustomInfiniteScroll>
        </div>
    )
}