import _ from 'lodash'
import QuoteHelper from "./QuoteHelper";

class DeskingHelper {
    constructor(quotes, dealState) {
        this.quotes = quotes || []
        this.dealState = dealState
    }

    quotesByTermAndLender = () => {
        const map = {}
        this.quotes.map((quote) => {
            const helper = new QuoteHelper(null, quote)
            const term = helper.term()
            const lender = helper.lender()

            if (!(term in map)) {
                map[term] = {[lender]: quote}
            } else {
                map[term][lender] = quote
            }
        })
        return map
    }

    quotesByLenderAndTerm = () => {
        const map = {}
        this.quotes.map((quote) => {
            const helper = new QuoteHelper(null, quote)
            const lender = helper.lender()
            const term = helper.term()

            if (!(lender in map)) {
                map[lender] = {[term]: quote}
            } else {
                map[lender][term] = quote
            }
        })
        return map
    }

    lenders = () => _.uniq(this.quotes.map((quote) => {
        const helper = new QuoteHelper(null, quote)
        return helper.lender()
    }))

    terms = () => _.uniq(this.quotes.map((quote) => {
        const helper = new QuoteHelper(null, quote)
        return helper.term()
    })).sort()

    termsForLender = (lender) => _.uniq(this.quotes.flatMap((quote) => {
        const helper = new QuoteHelper(null, quote)
        const quoteLender = helper.lender()
        if (lender === quoteLender) {
            return [Number(helper.term())]
        } else {
            return []
        }
    }))

    quotesForTerm = (term) => this.quotes.filter((quote) => {
        const quoteHelper = new QuoteHelper(null, quote)
        return String(term) === String(quoteHelper.term())
    })

    quotesForTermsSortedByBest = (term, isPurchase) => {
        const quotesForTerm = this.quotesForTerm(term)
        return quotesForTerm.sort((a, b) => {
            const helperA = new QuoteHelper(this.dealState, a)
            const helperB = new QuoteHelper(this.dealState, b)

            const funcName = isPurchase ? "monthlyPayment" : "advancePayment"

            return helperA[funcName]() - helperB[funcName]()
        })
    }

}

export default DeskingHelper