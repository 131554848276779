import React, { useState } from 'react';
import useEnterEffect from "../../../hooks/useEnterEffect";
import {useModalContext} from "../../../contexts/ModalContext";
import {toast} from "react-toastify";
import {useUserContext} from "../../../contexts/UserContext";
import {useCRMContext} from "../../../contexts/CRMContext";
import {buildCarString, formatDate} from "../../../utils/utils";
import classnames from "classnames";
import Badge from "@mui/material/Badge";
import {ClientItemProfitPopover} from "./ClientItemProfitPopover";
import DatePicker from "react-datepicker";
import moment from "moment";


const getInitialState = (columns) => ({
    name: '',
    phone_number: '',
    email: '',
    deals: [],
    column_entries: Object.keys(columns).reduce((acc, key) => {acc[key] = ''; return acc; }, {}),
    car_detail: null,
    trade_in_profit: 0,
    trade_in_profit_status: 'UNPAID',
    broker_fee_profit: 0,
    broker_fee_profit_status: 'UNPAID',
    other_profit: 0,
    other_profit_status: 'UNPAID',
    total_profit: 0,
    inception_date: null,
    maturity_date: null,
})

export const CreateClientItem = () => {
    const { profileState } = useUserContext()
    const columns = profileState.client_columns
    const sortedColumns = Object.keys(columns).sort((a, b) => columns[a] - columns[b])

    const { createClient } = useCRMContext()
    const [state, setState] = useState(getInitialState(columns))
    const [isFocused, setIsFocused] = useState(false);
    const { setIsCRMListDealsModalOpen, setIsDealModalOpen } = useModalContext()


    const handleOnFocus = () => {
        setIsFocused(true);
    }

    const handleBlur = () => {
        setIsFocused(false);
    }

    const createClientItem = () => {
        return createClient(state).then((data) => {
            toast.success("Successfully created client.")
            setState(getInitialState(columns))
        })
    }

    const getMaturityDate = (term, newInceptionDate) => {
        const inceptionDate = moment(newInceptionDate)
        const maturity_date = inceptionDate.clone().add(Number(term), 'M')
        return maturity_date.format("MM/DD/yyyy")
    }

    useEnterEffect(function enterCreateClient() {
        if (isFocused) {
            createClientItem()
        }
    }, [isFocused, state], false)

    return (
        <tr className={'ClientItem CreateClientItem-row'}>

            <th className={'CreateClientItem-th'}>
                <input
                    onFocus={handleOnFocus}
                    onBlur={handleBlur}
                    id={'CreateClientItem-name'}
                    type="text"
                    className={'material-icons"'}
                    placeholder={"New Client"}
                    onChange={(e) => setState({...state, name: e.target.value})}
                    value={state.name}
                />
            </th>
            <th className={'CreateClientItem-th'}>
                <input
                    onFocus={handleOnFocus}
                    onBlur={handleBlur}
                    id={'CreateClientItem-phone_number'}
                    type="text"
                    placeholder={'Phone'}
                    onChange={(e) => setState({...state, phone_number: e.target.value})}
                    value={state.phone_number}
                />
            </th>
            <th className={'CreateClientItem-th'}>
                <input
                    onFocus={handleOnFocus}
                    onBlur={handleBlur}
                    id={'CreateClientItem-email'}
                    type="email"
                    placeholder={'Email'}
                    onChange={(e) => setState({...state, email: e.target.value})}
                    value={state.email}
                />
            </th>
            <th
                className={'CreateClientItem-th'}
            >
                {
                    Boolean(state.deals.length) &&
                    <span>
                        <span
                            className={'ClientItem-td_deal'}
                            onClick={() => setIsDealModalOpen({id: state.deals})}>
                            {buildCarString(state?.car_detail)}
                        </span> |
                        <span
                            className={'ClientItem-td_deal'}
                            onClick={() => {
                                setIsCRMListDealsModalOpen({
                                    onlyOneSelection: true,
                                    selectedDealIds: state.deals,
                                    onSelect: (dealId, selectedDeal) => {
                                        if (state.inception_date) {
                                            setState({
                                                ...state,
                                                deals: [dealId],
                                                car_detail: selectedDeal.car_detail,
                                                maturity_date: getMaturityDate(selectedDeal.term, state.inception_date),
                                            })
                                        } else {
                                            setState({...state, deals: [dealId], car_detail: selectedDeal.car_detail})
                                        }
                                        setIsCRMListDealsModalOpen(null)
                                    }
                                })
                            }}
                        >
                            Edit
                        </span>
                    </span>
                }
                {
                    Boolean(!state.deals.length) &&
                    <div
                        onClick={() => {
                            setIsCRMListDealsModalOpen({
                                onlyOneSelection: true,
                                selectedDealIds: state.deals,
                                onSelect: (dealId, selectedDeal) => {
                                    if (state.inception_date) {
                                        setState({
                                            ...state,
                                            deals: [dealId],
                                            car_detail: selectedDeal.car_detail,
                                            maturity_date: getMaturityDate(selectedDeal.term, state.inception_date),
                                        })
                                    } else {
                                        setState({...state, deals: [dealId], car_detail: selectedDeal.car_detail})
                                    }
                                    setIsCRMListDealsModalOpen(null)
                                }
                            })
                        }}
                        className={'ClientItem-td_deal'}
                    >
                        Add Vehicle
                    </div>
                }
            </th>
            <th
                className={classnames('CreateClientItem-th profit', {
                    isPaid: false,
                })}
                key='Profit'
            >
                <Badge
                    showZero
                    badgeContent={state.number_of_profits}
                    sx={{ "& .MuiBadge-badge": { fontSize: 9, height: 15, minWidth: 15 } }}

                >
                    <ClientItemProfitPopover
                        clientState={state}
                        setClientState={setState}
                        handleOnFocus={handleOnFocus}
                        handleBlur={handleBlur}
                    />
                </Badge>
            </th>
            <th className={'CreateClientItem-th'}>
                <DatePicker
                    dateFormat="MM/dd/yyyy"
                    className={'CustomReactDatePicker'}
                    selected={state.inception_date}
                    // onSelect={(date) => console.log(date)}
                    onChange={(date) => setState({...state, inception_date: formatDate(date)})}
                />
            </th>
            <th className={'CreateClientItem-th'}>
                <DatePicker
                    dateFormat="MM/dd/yyyy"
                    className={'CustomReactDatePicker'}
                    selected={state.maturity_date}
                    // onSelect={(date) => console.log(date)}
                    onChange={(date) => setState({...state, maturity_date: formatDate(date)})}
                />
            </th>
            {
                sortedColumns.map((columnNum) => (
                    <th className={'CreateClientItem-th'} key={columnNum}>
                        <input
                            onFocus={handleOnFocus}
                            onBlur={handleBlur}
                            type="text"
                            onChange={(e) => {
                                setState(
                                    {
                                        ...state,
                                        column_entries: {
                                            ...state.column_entries,
                                            [columnNum]: e.target.value
                                        }
                                    })
                            }}
                            value={state.column_entries[columnNum]}
                        />
                    </th>
                ))
            }
            <th className={'CreateClientItem-th'}>

            </th>

        </tr>
    )
}