import React from 'react'
import Box from "@mui/material/Box";

import {useMediaQuery} from "react-responsive";
import {mediaQueryForMobile} from "../../HomePage/HomePage";
import {DealCRMLeadsPageHeader} from "./DealCRMLeadsPageHeader";
import {DealCRMLeadsTable} from "./DealCRMLeadsTable";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import {useDealCRMContext} from "../../../contexts/DealCRMPageContext";
import {MiniLeadItem} from "./MiniLeadItem";



export const DealCRMLeadsPage = () => {
    const isTabletOrMobile = useMediaQuery({ query: mediaQueryForMobile })

    const { selectedLeads, setSelectedLeads } = useDealCRMContext()

    const toggleSelected = (leadId) => {
        let newList = structuredClone(selectedLeads)
        if (selectedLeads.includes(leadId)) {
            newList = newList.filter(id => id !== leadId)
        } else {
            newList = [...newList, leadId]
        }
        setSelectedLeads([...newList])
    }

    return (
        <Box
            id={"LeadsPage"}
            style={{
                display: isTabletOrMobile ? '' : 'flex',
                height: '100vh',
                overflow: 'hidden',
                flexDirection: 'column',
            }}
        >
            <Box
                style={{
                    display: isTabletOrMobile ? 'flex' : '',
                    // padding: isTabletOrMobile ? '30px' : '60px 30px',
                    maxWidth: isTabletOrMobile ? '100%' : '',
                    flexDirection: isTabletOrMobile ? 'column' : ''
                }}
            >
                <DealCRMLeadsPageHeader/>
            </Box>
            <Box
                style={{
                    display: isTabletOrMobile ? 'flex' : '',
                    // padding: isTabletOrMobile ? '30px' : '0px 30px',
                    maxWidth: isTabletOrMobile ? '100%' : '',
                    flexDirection: isTabletOrMobile ? 'column' : '',
                    position: 'relative',
                    height: '100%',
                }}

            >
                <Divider className={'divider'}/>
                <Stack
                    direction="row"
                    // justifyContent="space-between"
                    alignItems="flex-start"
                    className={'input-group'}
                    style={{
                        height: '56px',
                    }}
                >
                    {
                        selectedLeads.map((leadId) => (
                            <MiniLeadItem
                                key={leadId}
                                leadId={leadId}
                                toggleSelected={() => toggleSelected(leadId)}
                            />
                            )
                        )
                    }
                </Stack>

                <Divider className={'divider'} sx={{ marginBottom: '10px'}}/>

                <DealCRMLeadsTable/>
            </Box>
        </Box>
    )
}