import FeeHelper from "../../utils/FeeHelper";
import React from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import classnames from "classnames";
import Switch from "@mui/material/Switch";
import CustomCurrencyInput from "../../components/CustomCurrencyInput";
import useFeeStateHook from "../../hooks/useFeeStateHook";

export const DealPageFee = ({fee, isLoading, isTabletOrMobile, isCappingDisabled, isEditingDisabled}) => {
    const [feeState, setFeeStateWithUpdate] = useFeeStateHook(fee)
    const feeHelper = new FeeHelper(feeState)

    if (!feeState) return null;

    const isFinanced = feeState.Type === "Financed"

    return (
        <TableRow
            key={feeHelper.uniqueId()}
            sx={{
                '&:last-child td, &:last-child th': {border: 0},
                height: '10px',
            }}
        >

            <TableCell
                className={'DealPageFee fee-name'}
                scope="row"
                size={'small'}
                width={'60%'}
                align="left"
            >
                {feeState.Name}
            </TableCell>
            <TableCell
                className={'DealPageFee'}
                align="right"
                size={'small'}
                width={'20%'}
            >
                {
                    !isCappingDisabled &&
                    <Switch
                        disabled={isLoading}
                        checked={isFinanced}
                        onChange={() => {
                            const type = feeState?.Type === 'Financed' ? 'Upfront' : 'Financed'
                            setFeeStateWithUpdate({Type: type})
                        }}
                    />
                }

            </TableCell>
            <TableCell
                align="right"
                size={'small'}
                className={'DealPageFee'}
                width={'60%'}
            >
                <CustomCurrencyInput
                    onChange={(value) => setFeeStateWithUpdate({Amount: value}, false)}
                    className={classnames('payment-row_value custom-fee-input', {
                        mobileBlue: isTabletOrMobile && isFinanced,
                        loading: isLoading
                    })}
                    prefix="$"
                    value={feeState.Amount}
                    key={feeHelper.uniqueId()}
                    allowNull={true}
                    disabled={isEditingDisabled}
                />
            </TableCell>
        </TableRow>
    )
}