import React, {createContext, useContext, useEffect, useState, useCallback} from 'react';
import {useFetchHook} from "../hooks/useFetchHook";
import fetcher from "../fetcher";
import useDidMountEffect from "../hooks/useDidMountEffect";
import {useDebounce} from "@uidotdev/usehooks";
import {toast} from "react-toastify";


export const CRMListDealsContext = createContext(undefined)

const initialPaginationResponse = {
    count: 0,
    next: null,
    previous: null,
    results: [],
    has_more: false,
    current_page: 0
}

export const CRMListDealsContextProvider = ({ children, initialSelectedIds, ...props } ) => {
    const [listDealsState, setListDealState] = useState(initialPaginationResponse)

    const [fetchListDealsResponse, fetchListDeals] = useFetchHook(fetcher.getDeals)
    const [deleteDealResponse, deleteDeal] = useFetchHook(fetcher.deleteDeal)
    const [deletedIdsState, setDeletedIds] = useState([])

    const [searchValue, setSearchValue] = useState('')
    const debouncedSearch = useDebounce(searchValue, 300)

    // for onlyOneSelection: True, returning the deal itself instead of fetching it with its ID
    const [selectedDeal, setSelectedDeal] = useState(null)

    const buildSearchParams = (value) => {
        return new URLSearchParams({
            s: value,
        }).toString()
    }

    const fetchNextDeals = () => {
        let queryParams = ''
        if (listDealsState.current_page !== 0) {
            queryParams += `page=${listDealsState.current_page + 1}`
        }

        if (searchValue) {
            queryParams += `&${buildSearchParams(searchValue)}`
        }

        return fetchListDeals(queryParams)
    }

    const shouldFetchNextDeals = () => {
        const data = fetchListDealsResponse?.data?.data
        if (!data?.has_more) return false;
        if (fetchListDealsResponse?.loading) return false;

        return true
    }

    const removeDeletedDealFromList = (id) => {
        const newList = listDealsState.results.filter((deal) => deal.id !== id)
        const newDeletedIds = deletedIdsState
        newDeletedIds.push(id)
        setDeletedIds(newDeletedIds)
        setListDealState({...listDealsState, results: newList})
    }

    useDidMountEffect(function fetchDeals(){
        if (debouncedSearch) {
            fetchListDeals(buildSearchParams(searchValue))
        } else {
            fetchListDeals()
        }
    }, [debouncedSearch])


    useEffect(function fetchListDealsFromBackendOnMount(){
        fetchNextDeals()
    }, [])

    useDidMountEffect(function updateListDealsFromBackend(){
        const response = fetchListDealsResponse?.data?.data

        if (response) {
            const currentPage = response.current_page
            if (currentPage === 1) {
                setListDealState(fetchListDealsResponse?.data?.data)
            } else {
                if (currentPage !== listDealsState.current_page) {
                    const currentList = listDealsState.results
                    const data = fetchListDealsResponse?.data?.data

                    const newList = currentList.concat(data.results).filter((d) => !deletedIdsState.includes(d.id))
                    setListDealState({...data, results: newList})

                }
            }
        }

    }, [fetchListDealsResponse])

    useDidMountEffect(function updateListAfterDelete() {
        if (deleteDealResponse?.data?.data?.id) {
            removeDeletedDealFromList(deleteDealResponse?.data?.data?.id)
            toast.success("Successfully deleted deal.")
        }
    }, [deleteDealResponse])

    const value = {
        listDealsState,
        setListDealState,

        fetchListDeals,
        fetchListDealsResponse,

        deleteDeal,
        deleteDealResponse,

        searchValue,
        setSearchValue,

        fetchNextDeals,
        removeDeletedDealFromList,

        shouldFetchNextDeals,

        selectedDeal,
        setSelectedDeal,

        ...props
    }

    return (
        <CRMListDealsContext.Provider value={value}>
            { children }
        </CRMListDealsContext.Provider>
    )
}

export const useCRMListDealsContext = () => {
    const context = useContext(CRMListDealsContext)

    if (context === undefined) {
        throw new Error('useCRMListDealsContext must be used within CRMListDealsContext')
    }
    return context
}