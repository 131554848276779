import React from 'react';

import CustomInfiniteScroll from "../../../components/CustomInfiniteScroll";
import {DealCRMClientsTableHeaders} from "./DealCRMClientsTableHeaders";
import {DealCRMClientItem} from "./DealCRMClientItem";
import {useDealCRMContext} from "../../../contexts/DealCRMPageContext";

export const DealCRMClientsTable = () => {
    const { listClientsState, shouldFetchNextClients, fetchNextClients } = useDealCRMContext()

    const clients = listClientsState.results

    return (
        <div className={'parent-scroll-ref'} id={"DealCRMClientsTableParentScrollRef"}>
            <table className={'ClientsTable'}>
                <DealCRMClientsTableHeaders/>
                <CustomInfiniteScroll
                    // initialLoad={Boolean(!clients.length)}
                    useWindow={false}
                    pageStart={listClientsState.current_page}
                    loadMore={(page) => {
                        return Boolean(shouldFetchNextClients() && fetchNextClients())
                    }}
                    hasMore={listClientsState.has_more}
                    element={'tbody'}
                    className={'ClientTable-container'}
                    getScrollParent={() => document.getElementById("DealCRMClientsTableParentScrollRef")}
                >
                    {clients.map((client, idx) => (
                        <DealCRMClientItem key={client.id} client={client}/>
                    ))}
                </CustomInfiniteScroll>
            </table>
        </div>
    )
}