import React, { useEffect, useState } from 'react'
import {useCRMListDealsContext} from "../../../contexts/CRMListDealsContext";
import QuoteHelper from "../../../utils/QuoteHelper";

import {useModalContext} from "../../../contexts/ModalContext";
import classnames from 'classnames'
import fetcher from "../../../fetcher";
import Stack from "@mui/material/Stack";
import {useMediaQuery} from "react-responsive";
import {mediaQueryForMobile} from "../../HomePage/HomePage";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import {useFetchResource} from "../../../hooks/useFetchResource";
import {CRMListDealCard} from "./CRMListDealCard";

export const MiniDealCard = ({ dealId, defaultChecked }) => {
    const [dealState, setDealState] = useFetchResource(fetcher.getDeal, dealId)

    if (!dealState) return null;

    return <CRMListDealCard deal={dealState} defaultChecked={defaultChecked}/>

}