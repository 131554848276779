import React from 'react'
import Box from "@mui/material/Box";

import {useMediaQuery} from "react-responsive";
import {mediaQueryForMobile} from "../../HomePage/HomePage";
import {LeadsTable} from "./LeadsTable";
import {LeadsPageHeader} from "./LeadsPageHeader";

export const LeadsPage = () => {
    const isTabletOrMobile = useMediaQuery({ query: mediaQueryForMobile })

    return (
            <Box
                id={"LeadsPage"}
                style={{
                    display: isTabletOrMobile ? '' : 'flex',
                    height: '100vh',
                    overflow: 'hidden',
                    flexDirection: 'column',
                }}
            >
                <Box
                    style={{
                        display: isTabletOrMobile ? 'flex' : '',
                        // padding: isTabletOrMobile ? '30px' : '60px 30px',
                        maxWidth: isTabletOrMobile ? '100%' : '',
                        flexDirection: isTabletOrMobile ? 'column' : ''
                    }}
                >
                    <LeadsPageHeader/>
                </Box>
                <Box
                    style={{
                        display: isTabletOrMobile ? 'flex' : '',
                        // padding: isTabletOrMobile ? '30px' : '0px 30px',
                        maxWidth: isTabletOrMobile ? '100%' : '',
                        flexDirection: isTabletOrMobile ? 'column' : '',
                        position: 'relative',
                        height: "calc(100% - 77px)",
                    }}

                >
                    <LeadsTable/>
                </Box>
            </Box>
    )
}