import {useEffect, useRef} from "react";

export const useDebounceEffect = (fnc, deps, delay) => {
    const ref = useRef();

    useEffect(() => {
        clearTimeout(ref.current);
        ref.current = setTimeout(() => {
            fnc();
            clearTimeout(ref.current);
        }, delay);
    }, [...deps, fnc, delay]);
};

export const useDidMountDebounceEffect = (fnc, deps, delay) => {
    const ref = useRef();
    const didMount = useRef(false);

    useEffect(() => {
        clearTimeout(ref.current);
        if (didMount.current) {
            ref.current = setTimeout(() => {
                fnc();
                clearTimeout(ref.current);
            }, delay);
        } else {
            didMount.current = true
        }
    }, [...deps, fnc, delay]);
};