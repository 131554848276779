import React, {useState} from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {useCRMContext} from "../../../contexts/CRMContext";
import Popover from "@mui/material/Popover";
import Stack from "@mui/material/Stack";
import DeleteSharpIcon from "@mui/icons-material/DeleteSharp";
import Typography from "@mui/material/Typography";

export const CRMTableTabs = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const { currentMonth, setCurrentMonth, currentYear, setCurrentYear} = useCRMContext()

    const handleActionClick = (event) => {
        event.stopPropagation()
        setAnchorEl(event.currentTarget);
    };

    const handleActionClose = () => {
        setAnchorEl(null);
    };

    const thisYear = new Date().getFullYear()
    const lastYear = new Date().getFullYear() - 1
    const twoYearsAgo = lastYear - 1

    return (
        <Stack
            direction="row"
            // justifyContent="space-between"
            alignItems="center"
            style={{
                position: 'absolute',
                bottom: '10px',
            }}
            className={'CRMTableTabs'}
        >
            <span
                className={'CRMTableTabs__year'}
                onClick={handleActionClick}
            >
                {currentYear}
            </span>
            <Popover
                onClick={(e) => e.stopPropagation()}
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleActionClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    horizontal: 'center',
                    vertical: 'bottom'
                }}
            >
                <Typography
                    sx={{ p: 2 }}
                    className={'CRM__action-item'}
                    onClick={() => {
                        setCurrentYear(twoYearsAgo)
                        handleActionClose()
                    }}
                >
                    {twoYearsAgo}
                </Typography>
                <Typography
                    sx={{ p: 2 }}
                    className={'CRM__action-item'}
                    onClick={() => {
                        setCurrentYear(lastYear)
                        handleActionClose()
                    }}
                >
                    {lastYear}
                </Typography>
                <Typography
                    sx={{ p: 2 }}
                    className={'CRM__action-item'}
                    onClick={() => {
                        setCurrentYear(thisYear)
                        handleActionClose()
                    }}
                >
                    {thisYear}
                </Typography>
            </Popover>
            <Tabs
                variant={'fullWidth'}
                value={currentMonth}
                onChange={(value, newValue) => {
                    setCurrentMonth(newValue)
                }}
            >
                <Tab
                    disableRipple
                    label="Jan"
                    value={0}
                    sx={{
                        '.MuiTabs-indicator': {
                            left: 0,
                        },
                    }}
                />
                <Tab disableRipple label="Feb" value={1} />
                <Tab disableRipple label="Mar" value={2} />
                <Tab disableRipple label="Apr" value={3} />
                <Tab disableRipple label="May" value={4} />
                <Tab disableRipple label="Jun" value={5} />
                <Tab disableRipple label="Jul" value={6} />
                <Tab disableRipple label="Aug" value={7} />
                <Tab disableRipple label="Sep" value={8} />
                <Tab disableRipple label="Oct" value={9} />
                <Tab disableRipple label="Nov" value={10} />
                <Tab disableRipple label="Dec" value={11} />
            </Tabs>
        </Stack>
    )
}