import React from 'react'

import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import {useUserContext} from "../../../contexts/UserContext";

import _ from 'lodash'
import {useNavigate} from "react-router";
import {useState} from "react";
import {usePricingContext} from "../../../contexts/PricingContext";

import {buildStyleDescriptionName} from "../../../utils/utils";
import {CreatePricingSectionMobile} from "./CreatePricingSectionMobile";

import './PricingListPageMobile.scss'
// import {PricingsListTableMobile} from "./PricingsListTableMobile";
import {ListDealsPage} from "../../ListDealsPage/ListDealsPage";
import {ListDealsContextProvider} from "../../../contexts/ListDealsContext";

const initialState = {
    year: '2024',
    model: null,
    trim: null,
    sd: null,
    car: null
}


export const PricingListPageMobile = ( { make } ) => {
    const [state, setState] = useState(initialState)
    const { profileState } = useUserContext()
    const { pricingData, fetchPricingsResponse } = usePricingContext()
    const navigate = useNavigate();

    const handleMakeChange = (make) => {
        setState(initialState)
        navigate(`/pricing/${make}`)
    }

    const buildTrims = () => {
        return _.map((state.model?.trims), (trim) => {
            return _.map((trim.style_descriptions), (sd) => {
                return (
                    <MenuItem key={sd.id} value={sd}>
                        <div className={'trim-container'}>
                            <div className={'trim-name'}>{trim.name}</div>
                            <div className={'sd-name'}>
                                {buildStyleDescriptionName(trim.name, sd.name)}
                            </div>
                        </div>
                    </MenuItem>
                )
            })
        })
    }

    return (
        <Box id={"PricingListPageMobile"}>
            <Box className={'PricingListPageMobile_select-car'}>
                <h3>
                    Pricing Index
                </h3>
                <Box className={'select-rows-container'}>

                <div className={'select-row'}>
                    <label>Year</label>
                    <Select
                        disabled={fetchPricingsResponse?.loading}
                        className={'PricingListPageMobile-Select'}
                        labelId="pricing-select-year"
                        id="year-select"
                        value={state.year}
                        label="Year"
                        onChange={(e) => setState({...state, year: e.target.value, model: null, trim: {}})}
                    >
                        <MenuItem value={'2023'}>2023</MenuItem>
                        <MenuItem value={'2024'}>2024</MenuItem>
                        <MenuItem value={'2024'}>2025</MenuItem>
                    </Select>
                </div>
                <div className={'select-row'}>
                    <label>Brand</label>
                    <Select
                        disabled={fetchPricingsResponse?.loading}
                        className={'PricingListPageMobile-Select'}
                        labelId="pricing-select-make"
                        id="make-select"
                        value={make}
                        label="Make"
                        onChange={(e) => handleMakeChange(e.target.value)}
                    >
                        {
                            profileState?.makes_list.map((make) => <MenuItem key={make} value={make}>{make}</MenuItem>)
                        }
                    </Select>
                </div>
                <div className={'select-row'}>
                    <label>Model</label>
                    <Select
                        disabled={fetchPricingsResponse?.loading || !state.year}
                        className={'PricingListPageMobile-Select'}
                        labelId="pricing-select-model"
                        id="model-select"
                        value={state.model}
                        label="Model"
                        onChange={(e) => setState({...state, model: e.target.value, sd: null})}
                    >
                        {
                            pricingData[state.year]?.map((model) => <MenuItem key={model.id} value={model}>{model.name}</MenuItem>)
                        }
                    </Select>
                </div>
                <div className={'select-row'}>
                    <label>Trim</label>
                    <Select
                        disabled={fetchPricingsResponse?.loading || !state.model}
                        className={'PricingListPageMobile-Select trim-select'}
                        labelId="pricing-select-trim"
                        id="trim-select"
                        value={state.sd}
                        label="Trim"
                        onChange={(e) => setState({...state, sd: e.target.value })}
                    >
                        {buildTrims()}
                    </Select>
                </div>
            </Box>
            </Box>
            {
                state.sd &&
                <CreatePricingSectionMobile style_description={state.sd}/>
            }
            {/*
                UNSURE IF THIS IS NECESSARY AT ALL
            */}
            {/*{*/}
            {/*    Boolean(state.sd?.pricings?.length) &&*/}
            {/*    <PricingsListTableMobile pricings={state.sd?.pricings}/>*/}
            {/*    <ListDealsContextProvider make={make}>*/}
            {/*        <ListDealsPage/>*/}
            {/*    </ListDealsContextProvider>*/}
            {/*}*/}
        </Box>
    )
}