import {useState} from "react";


export const useFetchHook = (fetchingFunction) => {

    const initialResponse = {
        error: null,
        loading: false,
        data: null
    }

    const [response, setResponse] = useState(initialResponse)


    const fetchData = (...args) => {
        setResponse({data: null, error: null, loading: true})

        return fetchingFunction(...args).then(
            (data) => {
                setResponse({
                    error: null,
                    loading: false,
                    data: data
                })
                return data
            })
            .catch((error) => {
                setResponse({
                    ...response,
                    error: error,
                    loading: false,
                })
                return Promise.reject(error)
            })
    }

    return [ response, fetchData ]
}