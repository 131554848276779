import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

export const CustomSelect = (props) => {
    const { className } = props
    return (
        <Autocomplete
            renderInput={(params) => (
                <TextField
                    sx={{ height: 30 }}
                    {...params}
                />
            )}
            disableClearable
            {...props}
            className={`${className}`}
        />
    )
}