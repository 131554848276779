import React from 'react';

import {ClientsTableHeaders} from "./ClientsTableHeaders";
import {ClientItem} from "./ClientItem";
import {useCRMContext} from "../../../contexts/CRMContext";
import CustomInfiniteScroll from "../../../components/CustomInfiniteScroll";

export const ClientsTable = () => {
    const { listClientsState, shouldFetchNextClients, fetchNextClients } = useCRMContext()

    const clients = listClientsState.results

    return (
        <div
            id={"ClientsTableParentScrollRef"}
            className={'parent-scroll-ref'}
        >
            <table className={'ClientsTable'}>
                <ClientsTableHeaders/>
                <CustomInfiniteScroll
                    initialLoad={Boolean(!clients.length)}
                    useWindow={false}
                    pageStart={listClientsState.current_page}
                    loadMore={(page) => {
                        return Boolean(shouldFetchNextClients() && fetchNextClients())
                    }}
                    hasMore={listClientsState.has_more}
                    element={'tbody'}
                    className={'ClientTable-container'}
                    getScrollParent={() => document.getElementById("ClientsTableParentScrollRef")}
                >
                    {clients.map((client, idx) => (
                        <ClientItem key={client.id} client={client}/>
                    ))}
                </CustomInfiniteScroll>
            </table>
        </div>
    )
}