import React from 'react'
import {CRMListDealCard} from "./CRMListDealCard";
import {useMediaQuery} from "react-responsive";
import {mediaQueryForMobile} from "../../HomePage/HomePage";
import {CRMListDealMobileCard} from "./CRMListDealMobileCard";
import {useCRMListDealsContext} from "../../../contexts/CRMListDealsContext";
import CustomInfiniteScroll from "../../../components/CustomInfiniteScroll";

export const CRMListDealsList = ( { listDealsState, selectedDealId } ) => {
    const isTabletOrMobile = useMediaQuery({ query: mediaQueryForMobile })

    const {
        fetchListDealsResponse,
        fetchNextDeals,
        shouldFetchNextDeals
    } = useCRMListDealsContext()

    return (
        <div className={'ListDealsList'}>
            <CustomInfiniteScroll
                useWindow={false}
                pageStart={0}
                loadMore={() => shouldFetchNextDeals() && fetchNextDeals()}
                hasMore={listDealsState.has_more}
                className={'ListDealsList_container'}
            >
                {
                    listDealsState?.results?.map((deal) => {
                        const isSelected = Boolean(selectedDealId === deal.id)

                        if (isTabletOrMobile) {
                            return <CRMListDealMobileCard
                                deal={deal}
                                key={deal.id}
                                isSelected={isSelected}
                            />
                        }

                        return <CRMListDealCard
                            deal={deal}
                            key={deal.id}
                            isSelected={isSelected}
                        />
                    })
                }
            </CustomInfiniteScroll>
        </div>
    )
}