import React, {useState} from 'react';
import Popover from "@mui/material/Popover";
import MenuItem from "@mui/material/MenuItem";
import Chip from "@mui/material/Chip";
import Select from "@mui/material/Select";

const ClientItemProfitStatus = ({ status, onChange }) => {
    return (
        <Select
            value={status}
            className={'ClientItem-td__status-select'}
            id={"Client-Status-Select"}
            onChange={onChange}
        >
            <MenuItem
                id={"ClientItem-td__status-select__success"}
                className={'ClientItem-td__status-select'}
                value={'PAID'}
            >
                <Chip label="PAID" color="success"/>
            </MenuItem>
            <MenuItem
                id={"ClientItem-td__status-select__error"}
                className={'ClientItem-td__status-select'}
                value={'UNPAID'}
            >
                <Chip label="UNPAID" color="error"/>
            </MenuItem>
        </Select>
    )
}

export const ClientItemProfitPopover = (props) => {
    const {
        clientState,
        setClientState,
        handleOnFocus,
        handleBlur
    } = props

    const [anchorEl, setAnchorEl] = useState(null);

    const handleActionClick = (event) => {
        event.stopPropagation()
        setAnchorEl(event.currentTarget);
    };

    const handleActionClose = () => {
        setAnchorEl(null);
    };

    const totalProfit = Number(clientState.trade_in_profit) + Number(clientState.broker_fee_profit) + Number(clientState.other_profit)

    return (
        <React.Fragment>
            <input
                onClick={handleActionClick}
                onFocus={(e) => {
                    handleOnFocus && handleOnFocus(e)
                }}
                onBlur={(e) => {
                    handleBlur && handleBlur(e)
                }}
                type="text"
                onChange={(e) => {
                    setClientState({...clientState, email: e.target.value})
                }}
                value={totalProfit.toDollarFormat()}
            />
            <Popover
                onClick={(e) => e.stopPropagation()}
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleActionClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    horizontal: 'right',
                    vertical: 'top'
                }}
            >
                <table className={'ClientItemProfitTable'}>
                    <thead>
                        <tr>
                            <th>Type</th>
                            <th>Amount</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Trade-in</td>
                            <td>
                                <input
                                    onFocus={handleOnFocus}
                                    onBlur={handleBlur}
                                    type="text"
                                    onChange={(e) => setClientState({...clientState, trade_in_profit: e.target.value})}
                                    value={clientState.trade_in_profit}
                                />
                            </td>
                            <td>
                                <ClientItemProfitStatus
                                    onChange={(e) => setClientState({...clientState, trade_in_profit_status: e.target.value})}
                                    status={clientState.trade_in_profit_status}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Broker Fee</td>
                            <td>
                                <input
                                    onFocus={handleOnFocus}
                                    onBlur={handleBlur}
                                    type="text"
                                    onChange={(e) => setClientState({...clientState, broker_fee_profit: e.target.value})}
                                    value={clientState.broker_fee_profit}
                                />
                            </td>
                            <td>
                                <ClientItemProfitStatus
                                    onChange={(e) => setClientState({...clientState, broker_fee_profit_status: e.target.value})}
                                    status={clientState.broker_fee_profit_status}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Other</td>
                            <td>
                                <input
                                    onFocus={handleOnFocus}
                                    onBlur={handleBlur}
                                    type="text"
                                    onChange={(e) => setClientState({...clientState, other_profit: e.target.value})}
                                    value={clientState.other_profit}
                                />
                            </td>
                            <td>
                                <ClientItemProfitStatus
                                    onChange={(e) => setClientState({...clientState, other_profit_status: e.target.value})}
                                    status={clientState.other_profit_status}
                                />
                            </td>
                        </tr>
                        <tr
                            className={'total-row'}
                        >
                            <td>
                                Total
                            </td>
                            <td>
                                <span>{totalProfit.toDollarFormat()}</span>
                            </td>
                            <td></td>
                        </tr>

                    </tbody>
                </table>
            </Popover>
        </React.Fragment>
    )
}