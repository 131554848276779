import {URLS} from "./urls";
import instance from "./axiosInstance";


const fetcher = {
    getCSRFCookie: () => instance.get(URLS.getCSRFCookie()),
    logError: (data) => instance.post(URLS.logError(), data),

    login: (email, password) => instance.post(URLS.login(), { email, password }),
    logout: () => instance.post(URLS.logout()),
    refreshToken: (token) => instance.post(URLS.refreshToken(), { token }),
    changePassword: (data) => instance.post(URLS.changePassword(), data),

    // Cars
    makesForYear: (year) => instance.get(URLS.makesForYear(year)),
    modelsForMake: (year, make) => instance.get(URLS.modelsForMake(year, make)),
    trimsForModel: (year, make, model) => instance.get(URLS.trimsForModel(year, make, model)),
    getCar: (data) => instance.post(URLS.getCar(), data),

    // Pricings
    getPricingsForMake: (make) => instance.get(URLS.getPricingsForMake(make)),
    createPricing: (data) => instance.post(URLS.createPricing(), data),
    updatePricing: (id, data) => instance.patch(URLS.updatePricing(id), data),
    deletePricing: (id) => instance.delete(URLS.deletePricing(id)),

    // Deals
    getDeal: (id, params = '') => instance.get(URLS.getDeal(id, params)),
    getDeals: (params = '') => instance.get(URLS.getDeals(params)),
    createDeal: (data) => instance.post(URLS.createDeal(), data),
    updateDeal: (id, data) => instance.patch(URLS.updateDeal(id), data),
    deleteDeal: (id) => instance.delete(URLS.deleteDeal(id)),

    // Desking
    getDesking: (data) => instance.post(URLS.getDesking(), data),

    // Profile
    getProfile: () => instance.get(URLS.getProfile()),
    getLocations: () => instance.get(URLS.getLocations()),
    createLocation: (data) => instance.post(URLS.createLocation(), data),
    updateLocation: (id, data) => instance.patch(URLS.updateLocation(id), data),
    deleteLocation: (id) => instance.delete(URLS.deleteLocation(id)),

    updateProfile: (data) => instance.patch(URLS.updateProfile(), data),

    // Leads
    getLead: (id, params = '') => instance.get(URLS.getLead(id, params)),
    getLeads: (params = '') => instance.get(URLS.getLeads(params)),
    createLead: (data) => instance.post(URLS.createLead(), data),
    updateLead: (id, data) => instance.patch(URLS.updateLead(id), data),
    deleteLead: (id) => instance.delete(URLS.deleteLead(id)),
    uploadLeadsCSV: (data, config) => instance.post(URLS.uploadLeadsCSV(), data, config),
    bulkUploadLeads: (data, config) => instance.post(URLS.bulkUploadLeads(), data, config),

    // Clients
    getClient: (id, params = '') => instance.get(URLS.getClient(id, params)),
    getClients: (params = '') => instance.get(URLS.getClients(params)),
    createClient: (data) => instance.post(URLS.createClient(), data),
    updateClient: (id, data) => instance.patch(URLS.updateClient(id), data),
    deleteClient: (id) => instance.delete(URLS.deleteClient(id)),
    bulkUploadClients: (data, config) => instance.post(URLS.bulkUploadClients(), data, config),
}

export default fetcher