import React from 'react';

import {useUserContext} from "../../../contexts/UserContext";
import DatePicker from "react-datepicker";
import Stack from "@mui/material/Stack";
import {formatDate} from "../../../utils/utils";
import {ClientItemProfitPopover} from "../ClientsPage/ClientItemProfitPopover";


export const ConvertToClientItem = ( {clientState, setClientState, getMaturityDate, clientDealState} ) => {
    const { profileState } = useUserContext()
    const columns = profileState.client_columns
    const sortedColumns = Object.keys(columns).sort((a, b) => a - b)

    return (
        <Stack
            direction="column"
            alignItems={"start"}
            className={'ConvertToClientItem'}
            style={{
                marginRight: '30px',
            }}
        >
            <span>Client Info</span>
            <div className={'ConvertToClient-div'}>
                <label>Name</label>
                <input
                    id={'ConvertToClient-name'}
                    type="text"
                    className={'material-icons"'}
                    placeholder={"New Client"}
                    onChange={(e) => setClientState({...clientState, name: e.target.value})}
                    value={clientState.name}
                />
            </div>
            <div className={'ConvertToClient-div'}>
                <label>Phone</label>
                <input
                    id={'ConvertToClient-phone_number'}
                    type="text"
                    placeholder={'Phone'}
                    onChange={(e) => setClientState({...clientState, phone_number: e.target.value})}
                    value={clientState.phone_number}
                />
            </div>
            <div className={'ConvertToClient-div'}>
                <label>Email</label>
                <input
                    id={'ConvertToClient-email'}
                    type="email"
                    placeholder={'Email'}
                    onChange={(e) => setClientState({...clientState, email: e.target.value})}
                    value={clientState.email}
                />
            </div>
            <div className={'ConvertToClient-div'}>
                <label>Inception Date</label>
                <DatePicker
                    dateFormat="MM/dd/yyyy"
                    className={'CustomReactDatePicker'}
                    selected={clientState.inception_date}
                    onChange={(date) => {
                        if (clientDealState) {
                            setClientState({...clientState, inception_date: formatDate(date), maturity_date: getMaturityDate(clientDealState.term, date)})
                        } else {
                            setClientState({...clientState, inception_date: formatDate(date)})
                        }
                    }}
                />
            </div>
            <div className={'ConvertToClient-div'}>
                <label>Maturity Date</label>
                <DatePicker
                    className={'CustomReactDatePicker'}
                    dateFormat="MM/dd/yyyy"
                    selected={clientState.maturity_date}
                    onChange={(date) => setClientState({...clientState, maturity_date: formatDate(date)})}
                />
            </div>
            {
                sortedColumns.map((columnNum) => (
                    <div className={'ConvertToClient-div'} key={columnNum}>
                        <label>{columns[columnNum].display_name}</label>
                        <input
                            id={`CreateClienttem-${columnNum}`}
                            type="text"
                            placeholder={columns[columnNum].display_name}
                            onChange={(e) => {
                                setClientState({...clientState, column_entries: {...clientState.column_entries, [columnNum]: e.target.value}})
                            }}
                            value={clientState.column_entries[columnNum]}
                        />
                    </div>
                ))
            }
            <div className={'ConvertToClient-div status_select'}>
                <div>
                    <label>Profit</label>
                </div>
                <ClientItemProfitPopover
                    clientState={{...clientDealState, ...clientState}}
                    setClientState={setClientState}
                />
            </div>
        </Stack>
    )
}