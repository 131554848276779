import React, { useState } from 'react';
import useEnterEffect from "../../../hooks/useEnterEffect";
import {useModalContext} from "../../../contexts/ModalContext";
import {toast} from "react-toastify";
import {useUserContext} from "../../../contexts/UserContext";
import {useCRMContext} from "../../../contexts/CRMContext";
import useDidMountEffect from "../../../hooks/useDidMountEffect";

const getInitialState = (columns, monthAdded) => ({
    name: '',
    phone_number: '',
    email: '',
    deals: [],
    column_entries: Object.keys(columns).reduce((acc, key) => {acc[key] = ''; return acc; }, {}),
    month_added: monthAdded,
})

export const CreateLeadItem = () => {
    const { profileState } = useUserContext()
    const columns = profileState.lead_columns
    const sortedColumns = Object.keys(columns).sort((a, b) => columns[a] - columns[b])
    const { createLead, currentMonth } = useCRMContext()

    const getMonthAdded = (month) =>  {
        return `${month + 1}/1/${new Date().getFullYear()}`
    }

    const [state, setState] = useState(getInitialState(columns, getMonthAdded(currentMonth)))
    const [isFocused, setIsFocused] = useState(false);
    const { setIsCRMListDealsModalOpen } = useModalContext()

    const handleOnFocus = () => {
        setIsFocused(true);
    }

    const handleBlur = () => {
        setIsFocused(false);
    }

    const createLeadItem = () => {
        return createLead(state).then((data) => {
            toast.success("Successfully created lead.")
            setState(getInitialState(columns))
        })
    }

    useDidMountEffect(function updateMonthAddedWhenMonthChanges(){
        setState({...state, month_added: getMonthAdded(currentMonth)})
    },[currentMonth])

    useEnterEffect(function enterCreateLead() {
        if (isFocused) {
            createLeadItem()
        }
    }, [isFocused, state], false)

    return (
        <tr className={'CreateLeadItem-row'}>

            <th className={'CreateLeadItem-th'}>
                <input
                    onFocus={handleOnFocus}
                    onBlur={handleBlur}
                    id={'CreateLeadItem-name'}
                    type="text"
                    placeholder={'New Lead'}
                    onChange={(e) => setState({...state, name: e.target.value})}
                    value={state.name}
                />
            </th>
            <th className={'CreateLeadItem-th'}>
                <input
                    onFocus={handleOnFocus}
                    onBlur={handleBlur}
                    id={'CreateLeadItem-phone_number'}
                    type="text"
                    placeholder={'Phone'}
                    onChange={(e) => setState({...state, phone_number: e.target.value})}
                    value={state.phone_number}
                />
            </th>
            <th className={'CreateLeadItem-th'}>
                <input
                    onFocus={handleOnFocus}
                    onBlur={handleBlur}
                    id={'CreateLeadItem-email'}
                    type="email"
                    placeholder={'Email'}
                    onChange={(e) => setState({...state, email: e.target.value})}
                    value={state.email}
                />
            </th>
            {
                sortedColumns.map((columnNum) => (
                    <th className={'CreateLeadItem-th'} key={columnNum}>
                        <input
                            onFocus={handleOnFocus}
                            onBlur={handleBlur}
                            type="text"
                            onChange={(e) => {
                                setState(
                                    {
                                        ...state,
                                        column_entries: {
                                            ...state.column_entries,
                                            [columnNum]: e.target.value
                                        }
                                    })
                            }}
                            value={state.column_entries[columnNum]}
                        />
                    </th>
                ))
            }

            <th
                className={'CreateLeadItem-th'}
            >
            </th>

            <th className={'CreateLeadItem-th'}>

            </th>

        </tr>
    )
}