import React from 'react'
import Box from "@mui/material/Box";

import {useMediaQuery} from "react-responsive";
import {mediaQueryForMobile} from "../../HomePage/HomePage";
import {DealCRMClientsPageHeader} from "./DealCRMClientsPageHeader";
import {DealCRMClientsTable} from "./DealCRMClientsTable";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import {useDealCRMContext} from "../../../contexts/DealCRMPageContext";
import {MiniClientItem} from "./MiniClientItem";

export const DealCRMClientsPage = () => {
    const isTabletOrMobile = useMediaQuery({ query: mediaQueryForMobile })

    const { selectedClients } = useDealCRMContext()

    return (
        <Box
            id={"ClientsPage"}
            style={{
                display: isTabletOrMobile ? '' : 'flex',
                height: '100vh',
                overflow: isTabletOrMobile ? 'auto' : 'hidden',
                flexDirection: 'column',
            }}
        >
            <Box
                style={{
                    display: isTabletOrMobile ? 'flex' : '',
                    // padding: isTabletOrMobile ? '30px' : '60px 30px',
                    maxWidth: isTabletOrMobile ? '100%' : '',
                    flexDirection: isTabletOrMobile ? 'column' : ''
                }}
            >
                <DealCRMClientsPageHeader/>
            </Box>
            <Box
                style={{
                    display: isTabletOrMobile ? 'flex' : '',
                    // padding: isTabletOrMobile ? '30px' : '60px 30px',
                    maxWidth: isTabletOrMobile ? '100%' : '',
                    flexDirection: isTabletOrMobile ? 'column' : '',
                    height: '100%',
                }}

            >
                <Divider className={'divider'}/>
                <Stack
                    direction="row"
                    // justifyContent="space-between"
                    alignItems="flex-start"
                    className={'input-group'}
                    style={{
                        height: '56px',
                    }}
                >
                    {
                        selectedClients.map((clientId) => <MiniClientItem key={clientId} clientId={clientId}/>)
                    }
                </Stack>

                <Divider className={'divider'} sx={{ marginBottom: '10px'}}/>

                <DealCRMClientsTable/>
            </Box>
        </Box>
    )
}