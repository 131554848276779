import React, {useState} from "react";
import {CSVImporter} from "csv-import-react";
import {toast} from "react-toastify";
import {useUserContext} from "../../../contexts/UserContext";
import {useCRMContext} from "../../../contexts/CRMContext";
import {useModalContext} from "../../../contexts/ModalContext";

export const UploadCSVModal = ( { isOpen, setIsOpen, bulkUploadFunc } ) => {
    const { profileState } = useUserContext()
    const { setIsUploadCSVModalOpen } = useModalContext()


    const uploadData = (data) => {
        return bulkUploadFunc(data).then((data) => {
            setIsUploadCSVModalOpen(null)
            toast.success("Successfully uploaded leads.")
        })
    }

    const columns = profileState.lead_columns
    const sortedColumns = Object.keys(columns).sort((a, b) => columns[a] - columns[b])

    const buildColumns = () => {
        return sortedColumns.map((columnNum) => {
            const col = columns[columnNum]
            return {
                name: col.display_name,
                key: columnNum,
                required: false,
                suggested_mappings: [col.display_name],
            }
        })
    }

    return (
        <CSVImporter
            modalIsOpen={isOpen}
            modalOnCloseTriggered={() => setIsOpen(false)}
            darkMode={false}
            onComplete={(data) => uploadData(data)}
            showDownloadTemplateButton={false}
            template={{
                columns: [
                    {
                        name: "Name",
                        key: 'name',
                        required: true,
                        suggested_mappings: ['Name', 'First Name', 'Last Name'],
                    },
                    {
                        name: "Email",
                        key: 'email',
                        required: false,
                        suggested_mappings: ['email_address', 'Email', 'Email Address'],
                    },
                    {
                        name: "Phone",
                        key: 'phone_number',
                        required: false,
                        suggested_mappings: ['Phone', 'Phone Number', 'phone_number'],
                    },
                    ...buildColumns()
                ],
            }}
        />
    )
}