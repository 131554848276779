import React, { useEffect, useState } from 'react'
import {useCRMListDealsContext} from "../../../contexts/CRMListDealsContext";
import QuoteHelper from "../../../utils/QuoteHelper";

import {useModalContext} from "../../../contexts/ModalContext";
import classnames from 'classnames'
import fetcher from "../../../fetcher";
import Stack from "@mui/material/Stack";
import {useMediaQuery} from "react-responsive";
import {mediaQueryForMobile} from "../../HomePage/HomePage";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import {useFetchResource} from "../../../hooks/useFetchResource";
import Checkbox from "@mui/material/Checkbox";

export const SelectMiniDealCard = ({ dealId, isSelected, setSelected }) => {
    const isTabletOrMobile = useMediaQuery({ query: mediaQueryForMobile })
    const [dealState, setDealState] = useFetchResource(fetcher.getDeal, dealId)
    const { setIsDealModalOpen } = useModalContext()

    if (!dealState) return null;

    const car_detail = dealState.car_detail
    const quoteHelper = new QuoteHelper(dealState)

    return (
        <Stack
            direction={isTabletOrMobile ? 'column' : 'row'}
            alignItems="center"
            className={classnames('SelectMiniDealCard', {
                isPurchase: dealState.is_purchase
            })}
            onClick={() => setIsDealModalOpen({id: dealId})}
        >
            <Checkbox
                className={'payment-row_value'}
                onClick={e => e.stopPropagation()}
                onChange={(e) => setSelected(dealId, dealState)}
                checked={isSelected}
            />
            <div>
                {car_detail.year} {car_detail.make} {car_detail.model}
                <br/>
                ${dealState.is_purchase ? quoteHelper.monthlyPayment() : quoteHelper.advancePayment()}
                {" "}/month {dealState.profit.toDollarFormat()} profit
            </div>
        </Stack>
    )
}