import React, {useEffect, useState} from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import {ConvertToClientItem} from "./ConvertToClientItem";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import {useUserContext} from "../../../contexts/UserContext";
import {SelectMiniDealCard} from "./SelectMiniDealCard";
import {useModalContext} from "../../../contexts/ModalContext";
import {formatDate} from "../../../utils/utils";
import moment from "moment";


const getInitialState = (lead, columns) => ({
    name: lead.name,
    phone_number: lead.phone_number,
    email: lead.email,
    status: 'UNPAID',
    deal: null,
    column_entries: Object.keys(columns).reduce((acc, key) => {acc[key] = ''; return acc; }, {}),
    deals: lead.deals,

    inception_date: formatDate(new Date()),
    maturity_date: null,
    // Profit info
    trade_in_profit: 0,
    trade_in_profit_status: 'UNPAID',
    broker_fee_profit: 0,
    broker_fee_profit_status: 'UNPAID',
    other_profit: 0,
    other_profit_status: 'UNPAID',
})


export const ConvertToClientPage = ( {lead, ...props} ) => {
    const { profileState } = useUserContext()
    const columns = profileState.client_columns
    const { setIsCRMListDealsModalOpen } = useModalContext()

    const [shouldDeleteLead, setShouldDeleteLead] = useState(true)
    const [clientState, setClientState] = useState(getInitialState(lead, columns))
    const [clientDealState, setClientDealState] = useState(null)

    const getMaturityDate = (term, newInceptionDate) => {
        const inceptionDate = moment(newInceptionDate)
        const maturity_date = inceptionDate.clone().add(Number(term), 'M')
        return maturity_date.format("MM/DD/yyyy")
    }

    const deals = clientState.deals || []

    return (
        <Stack
            direction="column"
            justifyContent="space-between"
            alignItems="left"
            className={''}
        >
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    className={'DealRebateModal-name'}
                >
                    <PersonAddIcon/> Convert Lead to Client
                </Typography>

            </Stack>
            <Divider
                className={'divider'}
                sx={{
                    margin: "20px 0px",
                }}
            />

            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
            >
                <Box
                    style={{
                        minWidth: '300px',
                    }}
                >
                    <label>Select Quote</label>
                    {
                        deals.map((dealId) => (
                            <SelectMiniDealCard
                                key={dealId}
                                dealId={dealId}
                                isSelected={clientState.deal === dealId}
                                setSelected={(dealId, selectedDeal) => {
                                    if (clientState.deal === dealId) {
                                        setClientState({...clientState, deal: null, maturity_date: null})
                                        setClientDealState(null)
                                    } else {
                                        setClientState(
                                            {
                                                ...clientState,
                                                deal: dealId,
                                                maturity_date: getMaturityDate(selectedDeal.term, clientState.inception_date),
                                                broker_fee_profit: selectedDeal.profit,
                                            }
                                        )
                                        setClientDealState(selectedDeal)
                                    }

                                }}
                            />
                            )
                        )
                    }
                    <div
                        style={{
                            textAlign: 'center'
                        }}
                    >
                        <span>or   </span>
                        <span
                            className={'link-text'}
                            onClick={() => {
                                setIsCRMListDealsModalOpen({
                                    initialSearchMoreOpen: true,
                                    onlyOneSelection: true,
                                    selectedDealIds: clientState.deals,
                                    onSelect: (dealId, selectedDeal) => {
                                        const newDeals = [dealId]
                                        setClientState({
                                            ...clientState,
                                            deal: dealId,
                                            deals: newDeals,
                                            maturity_date: getMaturityDate(selectedDeal.term, clientState.inception_date),
                                            broker_fee_profit: selectedDeal.profit,
                                        })
                                        setClientDealState(selectedDeal)
                                        setIsCRMListDealsModalOpen(null)
                                    },
                                })
                            }}
                        >
                             search quotes
                        </span>
                    </div>
                </Box>
                <ConvertToClientItem
                    clientDealState={clientDealState}
                    clientState={clientState}
                    setClientState={setClientState}
                    getMaturityDate={getMaturityDate}
                />
          </Stack>

            <Divider
                className={'divider'}
                sx={{
                    margin: "20px 0px",
                }}
            />

            <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
            >
                <span>
                    Delete Lead
                    <Checkbox
                        // className={'payment-row_value'}
                        onChange={() => setShouldDeleteLead(!shouldDeleteLead)}
                        checked={shouldDeleteLead}
                    />
                </span>

                <Button
                    className={'calc-reset-button'}
                    style={{
                        background: "#2B8EEA",
                        color: '#FFFFFF',
                        // marginLeft: '20px',
                    }}
                    onClick={() => {
                        const newClientState = structuredClone(clientState)
                        newClientState.deals = newClientState.deal ? [newClientState.deal] : null
                        props.convertClient(newClientState, shouldDeleteLead)
                    }}
                >
                    Convert
                </Button>
            </Stack>

        </Stack>
    )
}