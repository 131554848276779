import React from "react";

export const DeskingTableHeaders = ( { headers } ) => {

    return (
        <thead>
            <tr>
                <th className={'term'}>Term</th>
                {
                    headers.map((header, idx) => <th key={idx}> { header } </th>)
                }
            </tr>
        </thead>
    )
}