import React from "react";
import RequestQuoteOutlinedIcon from "@mui/icons-material/RequestQuoteOutlined";
import Stack from "@mui/material/Stack";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";

import {useUserContext} from "../../contexts/UserContext";
import Divider from "@mui/material/Divider";

export const DeskingSettings = () => {
    const { profileState, setProfileStateWithUpdate } = useUserContext()
    return (
        <Stack
            direction="column"
            justifyContent="space-between"
            alignItems="start"
        >
            <div className={'settings-header'}>
                <RequestQuoteOutlinedIcon />
                Desking Settings
            </div>

            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="start"
                >
                <FormControl
                    sx={{
                        flexDirection: 'row',
                        alignItems: 'center'
                    }}
                >
                    <FormLabel
                        id="demo-controlled-radio-buttons-group"
                        sx={{
                            marginRight: '10px'
                        }}
                    >
                        Sort Quotes By
                    </FormLabel>
                    <Divider className={'divider'}/>
                    <RadioGroup
                        row
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={profileState.desking_sort_by}
                        onChange={(e) => {
                            setProfileStateWithUpdate({desking_sort_by: e.target.value})
                        }}
                    >
                        <FormControlLabel value="BestPayment" control={<Radio />} label="Best Payment" />
                        <FormControlLabel value="Lender" control={<Radio />} label="Lender" />
                    </RadioGroup>
                </FormControl>
            </Stack>
        </Stack>
    )
}