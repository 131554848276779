import React, {useState, useCallback} from 'react';
import {toast} from "react-toastify";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import DeleteSharpIcon from "@mui/icons-material/DeleteSharp";
import {useModalContext} from "../../../contexts/ModalContext";
import {useUserContext} from "../../../contexts/UserContext";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import {useCRMContext} from "../../../contexts/CRMContext";
import {useDebounce} from "@uidotdev/usehooks";
import {buildCarString, buildTrimAndStyleDescriptionName, formatDate} from "../../../utils/utils";
import {ClientItemProfitPopover} from "./ClientItemProfitPopover";
import Badge from "@mui/material/Badge";
import classnames from 'classnames';
import DatePicker from "react-datepicker";
import moment from "moment";


const getInitialState = (client) => ({
    // name: client.name,
    // phone_number: client.phone_number,
    // email: client.email,
    // seeking: client.seeking,
    // trade_in_vehicle: client.trade_in_vehicle,
    // quotes: client.quotes,
    // ...client.column_entries,
    ...client
})

const funcMap = {
    Car: (column, clientState) => {
        if (clientState.column_entries[column]) {
            return clientState.column_entries[column]
        }
        const car_detail = clientState.car_detail
        if (car_detail) {
            const description = buildTrimAndStyleDescriptionName(car_detail.trim, car_detail.style_description)
            return `${car_detail?.year} ${car_detail.make} ${car_detail.model} ${description}`
        } else {
            return ''
        }

    }
}

export const ClientItem = ( { client } ) => {
    const { profileState } = useUserContext()
    const { updateClient, deleteClient, updateClientResponse } = useCRMContext()
    const [clientState, setClientState] = useState(getInitialState(client))
    const debouncedClientState = useDebounce(clientState, 300)
    // const prevState = usePrevious(clientState);
    const { setIsCRMListDealsModalOpen, setIsDealModalOpen, isCRMListDealsModalOpen } = useModalContext()

    const [isFocused, setIsFocused] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleOnFocus = useCallback(() => {
        setIsFocused(true);
    }, []);

    const handleBlur = useCallback(() => {
        setIsFocused(false);
    }, []);


    const handleActionClick = (event) => {
        event.stopPropagation()
        setAnchorEl(event.currentTarget);
    };

    const handleActionClose = () => {
        setAnchorEl(null);
    };

    const updateClientItem = () => {
        return updateClient(client.id, clientState).then((data) => {
            // toast.success("Successfully updated client.")
        })
    }

    const deleteClientItem = () => {
        return deleteClient(client.id).then((data) => {
            toast.success("Successfully deleted client.")
        })
    }

    const setStateWithUpdate = (newState) => {
        setClientState({...newState, _should_update: true})
    }

    const setStateWithoutUpdate = (newState) => {
        setClientState({...newState, _should_update: false})
    }

    const setClientQuotes = (toggledDealId) => {
        // right now we are only supporting one Deal per client, but this may change
        let newDealIds = structuredClone(clientState.deals)
        if (newDealIds.includes(toggledDealId)) {
            setStateWithUpdate({...clientState, deals: []})
        } else {
            setStateWithUpdate({...clientState, deals: [toggledDealId]})
        }
    }

    const getMaturityDate = (term, newInceptionDate) => {
        const inceptionDate = moment(newInceptionDate)
        const maturity_date = inceptionDate.clone().add(Number(term), 'M')
        return maturity_date.format("MM/DD/yyyy")
    }

    useDidMountEffect(function updateClientItemAfterDebounce(){
        if (debouncedClientState._should_update === true) {
            updateClientItem()
        }
    }, [debouncedClientState])

    const getColumnValue = (columnName) => {
        const func = funcMap[columnName]
        if (!func) {
            return clientState.column_entries[columnName]
        } else {
            return func(columnName, clientState)
        }

    }

    useDidMountEffect(function setClientItemStateOnUpdateResponse(){
        if (updateClientResponse?.data?.data && !updateClientResponse.loading && !updateClientResponse.error) {
            const newClientData = updateClientResponse?.data?.data
            if (newClientData.id === client.id) {
                const newDeals = newClientData.deals
                setStateWithoutUpdate({...clientState, deals: newDeals})

                if (isCRMListDealsModalOpen) {
                    setIsCRMListDealsModalOpen({
                        ...isCRMListDealsModalOpen,
                        selectedDealIds: newClientData.deals,
                        onSelect: (dealId) => setClientQuotes(dealId),
                    })
                }
            }
        }

    }, [updateClientResponse])

    if (!clientState) return null;

    const columns = profileState.client_columns
    const sortedColumns = Object.keys(columns).sort((a, b) => columns[a] - columns[b])

    return (
        <tr className={'ClientItem'}>
            <td className={'ClientItem-td'} key='Name'>
                <input
                    onFocus={handleOnFocus}
                    onBlur={handleBlur}
                    type="text"
                    onChange={(e) => {
                        setStateWithUpdate({...clientState, name: e.target.value})
                    }}
                    value={clientState.name}
                />
            </td>
            <td className={'ClientItem-td'} key='Phone'>
                <input
                    onFocus={handleOnFocus}
                    onBlur={handleBlur}
                    type="text"
                    onChange={(e) => {
                        setStateWithUpdate({...clientState, phone_number: e.target.value})
                    }}
                    value={clientState.phone_number}
                />
            </td>
            <td className={'ClientItem-td'} key='Email'>
                <input
                    onFocus={handleOnFocus}
                    onBlur={handleBlur}
                    type="text"
                    onChange={(e) => {
                        setStateWithUpdate({...clientState, email: e.target.value})
                    }}
                    value={clientState.email}
                />
            </td>
            <td
                className={'ClientItem-td deal'}
            >
                {
                    Boolean(clientState.deals.length) &&
                    <span>
                        <span
                            className={'ClientItem-td_deal'}
                            onClick={() => setIsDealModalOpen({id: clientState.deals})}>
                            {buildCarString(clientState?.car_detail)}
                        </span> {" "} | {" "}
                        <span
                            className={'ClientItem-td_deal'}
                            onClick={() => {
                                setIsCRMListDealsModalOpen({
                                    initialSearchMoreOpen: true,
                                    onlyOneSelection: true,
                                    selectedDealIds: clientState.deals,
                                    onSelect: (dealId) => setClientQuotes(dealId),
                                    onSave: (dealIds) => {
                                        setStateWithUpdate({...clientState, deals: dealIds})
                                    }
                                })
                            }}
                        >
                            Edit
                        </span>
                    </span>
                }
                {
                    Boolean(!clientState.deals.length) &&
                    <span
                        onClick={() => {
                            setIsCRMListDealsModalOpen({
                                onlyOneSelection: true,
                                selectedDealIds: clientState.deals,
                                onSelect: (dealId, selectedDeal) => {
                                    const newDeals = [dealId]
                                    if (clientState.inception_date) {
                                        setStateWithUpdate({
                                            ...clientState,
                                            deal: dealId,
                                            deals: newDeals,
                                            maturity_date: getMaturityDate(selectedDeal.term, clientState.inception_date),
                                            broker_fee_profit: selectedDeal.profit,
                                        })
                                    } else {
                                        setStateWithUpdate({
                                            ...clientState,
                                            deal: dealId,
                                            deals: newDeals,
                                            broker_fee_profit: selectedDeal.profit,
                                        })
                                    }

                                },
                            })
                        }}
                        className={'ClientItem-td_deal'}
                    >
                        {clientState.column_entries['Car'] ? clientState.column_entries['Car'] : 'Add Vehicle'}
                    </span>
                }
            </td>
            <td
                className={classnames('ClientItem-td profit', {
                    isPaid: clientState.is_paid,
                })}
                key='Profit'
            >
                <Badge
                    badgeContent={clientState.number_of_profits}
                    sx={{ "& .MuiBadge-badge": { fontSize: 9, height: 15, minWidth: 15 } }}

                >
                    <ClientItemProfitPopover
                        clientState={clientState}
                        setClientState={setStateWithUpdate}
                        handleOnFocus={handleOnFocus}
                        handleBlur={handleBlur}
                    />
                </Badge>
            </td>
            <td className={'ClientItem-td'} key={'Inception Date'}>
                <DatePicker
                    popperClassName={"ClientItem-datepicker"}
                    onFocus={handleOnFocus}
                    onBlur={handleBlur}
                    dateFormat="MM/dd/yyyy"
                    className={'CustomReactDatePicker'}
                    selected={clientState.inception_date}
                    onChange={(date) => () =>{
                        setStateWithUpdate({...clientState, inception_date: formatDate(date)})
                    }}
                />
            </td>
            <td className={'ClientItem-td'} key={'Maturity Date'}>
                <DatePicker
                    popperClassName={"ClientItem-datepicker"}
                    onFocus={handleOnFocus}
                    onBlur={handleBlur}
                    dateFormat="MM/dd/yyyy"
                    className={'CustomReactDatePicker'}
                    selected={clientState.maturity_date || clientState.deal_maturity_date}
                    onSelect={(date) => setStateWithUpdate({...clientState, maturity_date: formatDate(date)})}
                    onChange={(date) => () =>{
                        setStateWithUpdate({...clientState, maturity_date: formatDate(date)})
                    }}
                />
            </td>
            {
                sortedColumns.map(columnNum => (
                    <td className={'ClientItem-td'} key={columnNum}>
                        <input
                            onFocus={handleOnFocus}
                            onBlur={handleBlur}
                            type="text"
                            onChange={(e) => {
                                setStateWithUpdate(
                                    {
                                        ...clientState,
                                        column_entries: {
                                            ...clientState.column_entries,
                                            [columnNum]: e.target.value
                                        }
                                    })
                            }}
                            value={clientState.column_entries[columnNum] || ""}
                        />
                    </td>
                ))
            }

            <td className={'ClientItem-td'}>
                <MoreHorizIcon
                    onClick={handleActionClick}
                    className={'CRM-action-button'}
                />
                <Popover
                    onClick={(e) => e.stopPropagation()}
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={handleActionClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        horizontal: 'right',
                        vertical: 'top'
                    }}
                >
                    <Typography
                        sx={{ p: 2, fontWeight: 500 }}
                        className={'CRM__action-item'}
                        onClick={deleteClientItem}
                    >
                        <DeleteSharpIcon/>
                        Delete Client
                    </Typography>
                </Popover>
            </td>
        </tr>
    )
}