import React from 'react'
import Box from "@mui/material/Box";

import {useMediaQuery} from "react-responsive";
import {mediaQueryForMobile} from "../../HomePage/HomePage";
import {DealCRMLeadsPage} from "./DealCRMLeadsPage";
import {useDealCRMContext} from "../../../contexts/DealCRMPageContext";
import {DealCRMClientsPage} from "./DealCRMClientsPage";


export const DealCRMPage = () => {
    const { currentTab } = useDealCRMContext()
    const isTabletOrMobile = useMediaQuery({ query: mediaQueryForMobile })

    const isLeadsTab = currentTab === '/leads'
    return (
        <React.Fragment>
            <Box
                id={"CRMPage"}
                style={{
                    display: isTabletOrMobile ? '' : 'flex',
                    height: '100vh',
                    overflow: isTabletOrMobile ? 'auto' : 'hidden',
                    flexDirection: 'column',
                    padding: isTabletOrMobile ? '30px' : '60px 30px',
                }}
            >
                {
                    isLeadsTab ? <DealCRMLeadsPage/> : <DealCRMClientsPage/>
                }
            </Box>
        </React.Fragment>
    )
}