import React, {useEffect, useState} from "react";
import {DeskingTable} from "./DeskingTable";
import './DeskingPage.scss'
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {buildTrimAndStyleDescriptionName} from "../../utils/utils";
import {DeskingPageLoading} from "./DeskingPageLoading";
import {useDeskingContext} from "../../contexts/DeskingContext";
import Stack from "@mui/material/Stack";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {useUserContext} from "../../contexts/UserContext";
import Popover from "@mui/material/Popover";

export const DeskingPage = ( { id, onSave } ) => {
    const { profileState, setProfileStateWithUpdate } = useUserContext()
    const { deskingState, fetchDeskingResponse, updateDealResponse } = useDeskingContext()
    const [anchorEl, setAnchorEl] = useState(null);

    const handleActionClick = (event) => {
        event.stopPropagation()
        setAnchorEl(event.currentTarget);
    };

    const handleActionClose = () => {
        setAnchorEl(null);
    };

    useEffect(function onDeskingSave(){
        if (updateDealResponse?.data?.data && !updateDealResponse.loading && !updateDealResponse.error) {
            onSave && onSave()
        }
    }, [updateDealResponse])


    if (fetchDeskingResponse?.loading) {
        return <DeskingPageLoading/>
    }

    if (!deskingState) return null;

    const carDetail = deskingState?.car_detail

    const sortBy = profileState.desking_sort_by
    const sortByText = {
        [sortBy === 'BestPayment']: 'Monthly Payment: Low to High',
        [sortBy === 'Lender']: 'Sort By Lender',
    }.true

    const headerTitle = {
        true: 'Lease',
        [deskingState.is_purchase]: 'Purchase',
        [deskingState.is_purchase && deskingState.is_one_pay]: 'Cash'
    }.true

    return (
        <div id="DeskingPage">
            <Box className={'DeskingPage-Box'}>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Typography
                        className={'car-info'}
                        fontWeight={400}
                        variant="h5"
                        gutterBottom
                    >
                        Quotes for
                        <strong> {carDetail.year} {carDetail.make} {carDetail.model} </strong>
                        {buildTrimAndStyleDescriptionName(carDetail.trim, carDetail.style_description)}
                        {` [${headerTitle}]`}

                    </Typography>
                    <Typography
                        className={'desking-sort__drop-down-item'}
                        onClick={handleActionClick}
                        fontSize={14}
                    >
                        {sortByText}
                        <ArrowDropDownIcon/>
                    </Typography>
                    <Popover
                        onClick={(e) => e.stopPropagation()}
                        open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        onClose={handleActionClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            horizontal: 'right',
                            vertical: 'top'
                        }}
                    >
                        <Typography
                            className={'desking-sort__drop-down-item'}
                            fontSize={14}
                            onClick={(e) => {
                                setProfileStateWithUpdate({desking_sort_by: 'BestPayment'})
                                handleActionClose(e)
                            }}
                        >
                            Monthly Payment: Low to High
                        </Typography>
                        <Typography
                            className={'desking-sort__drop-down-item'}
                            onClick={(e) => {
                                setProfileStateWithUpdate({desking_sort_by: 'Lender'})
                                handleActionClose(e)
                            }}
                            fontSize={14}
                        >
                            Sort By Lender
                        </Typography>
                    </Popover>

                </Stack>

                <DeskingTable dealState={deskingState} dealId={id}/>
            </Box>
        </div>
    )
}