import React from 'react'
import Box from "@mui/material/Box";

import './CRM.scss'

import {useMediaQuery} from "react-responsive";
import {Outlet} from "react-router-dom";
import {mediaQueryForMobile} from "../HomePage/HomePage";
import {UploadCSVModal} from "./LeadsPage/UploadCSVModal";
import {useModalContext} from "../../contexts/ModalContext";
import {CRMTableTabs} from "./ClientsPage/CRMTableTabs";


export const CRMPage = () => {
    const isTabletOrMobile = useMediaQuery({ query: mediaQueryForMobile })
    const {
        isUploadCSVModalOpen,
        setIsUploadCSVModalOpen } = useModalContext()

    return (
        <React.Fragment>
            <Box
                id={"CRMPage"}
                style={{
                    display: isTabletOrMobile ? '' : 'flex',
                    height: '100vh',
                    overflow: isTabletOrMobile ? 'auto' : 'hidden',
                    flexDirection: 'column',
                    padding: isTabletOrMobile ? '30px' : '60px 30px',
                }}
            >
                <Outlet/>
                <CRMTableTabs/>
            </Box>

            <UploadCSVModal
                isOpen={Boolean(isUploadCSVModalOpen)}
                setIsOpen={setIsUploadCSVModalOpen}
                {...isUploadCSVModalOpen}
            />
        </React.Fragment>
    )
}