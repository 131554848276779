import React, {useState} from 'react';
import {toast} from "react-toastify";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import DeleteSharpIcon from "@mui/icons-material/DeleteSharp";
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';
import {useModalContext} from "../../../contexts/ModalContext";
import {useUserContext} from "../../../contexts/UserContext";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import {useCRMContext} from "../../../contexts/CRMContext";
import {useDebounce} from "@uidotdev/usehooks";
import Stack from "@mui/material/Stack";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import {formatDate} from "../../../utils/utils";


const getInitialState = (lead) => ({
    // name: lead.name,
    // phone_number: lead.phone_number,
    // email: lead.email,
    // seeking: lead.seeking,
    // trade_in_vehicle: lead.trade_in_vehicle,
    // quotes: lead.quotes,
    // ...lead.column_entries,
    ...lead
})

export const LeadItem = ( { lead } ) => {
    const [leadState, setLeadState] = useState(getInitialState(lead))
    const debouncedLeadState = useDebounce(leadState, 300)

    const { profileState } = useUserContext()
    const { updateLead, deleteLead, createClient, currentMonth, removeDeletedLeadFromList, updateLeadResponse } = useCRMContext()
    const {
        isCRMListDealsModalOpen,
        setIsCRMListDealsModalOpen,
        setIsConvertToClientModalOpen,
        setIsDealModalOpen,
    } =
        useModalContext()

    const [isFocused, setIsFocused] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleOnFocus = () => {
        setIsFocused(true);
    }

    const handleBlur = () => {
        setIsFocused(false);
    }

    const handleActionClick = (event) => {
        event.stopPropagation()
        setAnchorEl(event.currentTarget);
    };

    const handleActionClose = () => {
        setAnchorEl(null);
    };

    const updateLeadItem = () => {
        return updateLead(lead.id, leadState).then((data) => {
        })
    }

    const deleteLeadItem = () => {
        return deleteLead(lead.id).then((data) => {
            toast.success("Successfully deleted lead.")
        })
    }

    const convertClient = (data, shouldDeleteLead) => {
        return createClient(data).then((data) => {
            toast.success("Successfully created client.")
            if (shouldDeleteLead) {
                deleteLeadItem()
            }
            setIsConvertToClientModalOpen(null)
        })
    }

    const setStateWithUpdate = (newState) => {
        setLeadState({...newState, _should_update: true})
    }

    const setStateWithoutUpdate = (newState) => {
        setLeadState({...newState, _should_update: false})
    }

    const setLeadQuotes = (toggledDealId) => {
        let newDealIds = structuredClone(leadState.deals)
        if (newDealIds.includes(toggledDealId)) {
            newDealIds = newDealIds.filter((dealId) => dealId !== toggledDealId)
        } else {
            newDealIds = [...newDealIds, toggledDealId]
        }

        setStateWithUpdate({...leadState, deals: newDealIds})
    }

    useDidMountEffect(function updateLeadItemAfterDebounce(){
        if (debouncedLeadState._should_update === true) {
            updateLeadItem()
        }
    }, [debouncedLeadState])

    useDidMountEffect(function setLeadItemStateOnUpdateResponse(){
        if (updateLeadResponse?.data?.data && !updateLeadResponse.loading && !updateLeadResponse.error) {
            const newLeadData = updateLeadResponse?.data?.data
            if (newLeadData.id === lead.id) {
                const newDeals = newLeadData.deals
                setStateWithoutUpdate({...leadState, deals: newDeals})

                if (isCRMListDealsModalOpen) {
                    setIsCRMListDealsModalOpen({
                        ...isCRMListDealsModalOpen,
                        selectedDealIds: newLeadData.deals,
                        onSelect: (dealId) => setLeadQuotes(dealId),
                    })
                }
            }
        }

    }, [updateLeadResponse])

    if (!leadState) return null;

    const columns = profileState.lead_columns
    const sortedColumns = Object.keys(columns).sort((a, b) => a - b)

    const isCurrentMonth = currentMonth === new Date().getMonth()

    let rolloverMonth = new Date()
    if (isCurrentMonth) {
        rolloverMonth = new Date(rolloverMonth.getFullYear(), rolloverMonth.getMonth() + 1, 1)
    }

    return (
        <tr className={'LeadItem'}>
            <td className={'LeadItem-td'} key='Name'>
                <input
                    onFocus={handleOnFocus}
                    onBlur={handleBlur}
                    type="text"
                    onChange={(e) => {
                        setStateWithUpdate({...leadState, name: e.target.value})
                    }}
                    value={leadState.name}
                />
            </td>
            <td className={'LeadItem-td'} key='Phone'>
                <input
                    onFocus={handleOnFocus}
                    onBlur={handleBlur}
                    type="text"
                    onChange={(e) => {
                        setStateWithUpdate({...leadState, phone_number: e.target.value})
                    }}
                    value={leadState.phone_number}
                />
            </td>
            <td className={'LeadItem-td'} key='Email'>
                <input
                    onFocus={handleOnFocus}
                    onBlur={handleBlur}
                    type="text"
                    onChange={(e) => {
                        setStateWithUpdate({...leadState, email: e.target.value})
                    }}
                    value={leadState.email}
                />
            </td>
            {
                sortedColumns.map(columnNum => (
                    <td className={'LeadItem-td'} key={columnNum}>
                        <input
                            onFocus={handleOnFocus}
                            onBlur={handleBlur}
                            type="text"
                            onChange={(e) => {
                                setStateWithUpdate(
                                    {
                                        ...leadState,
                                        column_entries: {
                                            ...leadState.column_entries,
                                            [columnNum]: e.target.value
                                        }
                                    })
                            }}
                            value={leadState.column_entries[columnNum]}
                        />
                    </td>
                ))
            }

            <td
                className={'LeadItem-td'}
            >
                <Stack
                    direction="row"
                    justifyContent="space-around"
                    alignItems="center"
                    className={'input-group'}
                >
                    <div
                        className={'LeadItem-td_quotes'}
                        onClick={() => {
                            setIsCRMListDealsModalOpen({
                                selectedDealIds: leadState.deals,
                                onSelect: (dealId) => setLeadQuotes(dealId),
                                onSave: (dealIds) => {
                                    setStateWithUpdate({...leadState, deals: dealIds})
                                },
                            })
                        }}
                    >
                        {leadState.deals.length} Quote{leadState.deals.length === 1 ? "" : "s"}
                    </div> {" "} | {" "}
                    <div
                        className={'ClientItem-td_deal'}
                        onClick={() => {
                            window.onLeadCreate = function(createData) {
                                toast.success("Successfully created quote for lead.")
                                setIsDealModalOpen({id: createData.id})
                                setStateWithUpdate({...leadState, deals: [...leadState.deals, createData.id]})
                            }
                            window.open(`/calculate?lead_id=${lead.id}`)
                        }}
                    >
                      +
                    </div>
                </Stack>
            </td>

            <td className={'LeadItem-td'}>
                <MoreHorizIcon
                    onClick={handleActionClick}
                    className={'CRM-action-button'}
                />
                <Popover
                    // id={id}
                    onClick={(e) => e.stopPropagation()}
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={handleActionClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        horizontal: 'right',
                        vertical: 'top'
                    }}
                >
                    <Typography
                        sx={{ p: 2 }}
                        className={'CRM__action-item'}
                        onClick={deleteLeadItem}
                    >

                        <DeleteSharpIcon />
                        Delete Lead
                    </Typography>
                    <Typography
                        sx={{ p: 2 }}
                        className={'CRM__action-item'}
                        onClick={() => {
                            setIsConvertToClientModalOpen({
                                lead: leadState,
                                convertClient: convertClient,
                            })
                        }}
                    >

                        <SwitchAccountIcon />
                        Convert to Client
                    </Typography>
                    <Typography
                        sx={{ p: 2 }}
                        className={'CRM__action-item'}
                        onClick={() => {
                            return updateLead(lead.id, {...leadState, month_added: formatDate(rolloverMonth)}).then((data) => {
                                removeDeletedLeadFromList(lead.id)
                                toast.success("Success.")
                            })
                        }}
                    >

                        <CalendarMonthIcon />
                        Rollover to {rolloverMonth.toLocaleString('default', { month: 'long' })}
                    </Typography>
                </Popover>
            </td>
        </tr>
    )
}