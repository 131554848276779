import React from "react";
import DeskingHelper from "../../utils/DeskingHelper";
import {DeskingTableHeaders} from "./DeskingTableHeaders";
import {DeskingTableRow} from "./DeskingTableRow";
import {useMediaQuery} from "react-responsive";
import {mediaQueryForMobile} from "../HomePage/HomePage";
import {useUserContext} from "../../contexts/UserContext";

export const DeskingTable = ( { dealState, dealId, onSave } ) => {
    const { profileState } = useUserContext()
    const isTabletOrMobile = useMediaQuery({ query: mediaQueryForMobile })

    const allQuotes = dealState.quotes
    const deskingHelper = new DeskingHelper(allQuotes, dealState)

    const sortBy = profileState.desking_sort_by
    const isSortByLender = sortBy === 'Lender'

    const terms = deskingHelper.terms()
    const lenders = deskingHelper.lenders()

    const headers = isSortByLender ? lenders : []

    return (
        <div className={'desking-scroll-container'}>
            <table>
                <DeskingTableHeaders headers={headers} />
                <tbody>
                {
                    terms.map((term, index) => {
                        let quotes
                        if (sortBy === 'Lender') {
                            const quotesByTermAndLender = deskingHelper.quotesByTermAndLender()
                            quotes = quotesByTermAndLender[term]
                        } else if (sortBy === 'BestPayment') {
                            quotes = deskingHelper.quotesForTermsSortedByBest(term, dealState.is_purchase)
                        }

                        return <DeskingTableRow
                            sortBy={sortBy}
                            onSave={onSave}
                            key={index}
                            dealState={dealState}
                            term={term}
                            quotes={quotes}
                            lenders={lenders}
                            dealId={dealId}
                        />
                    })
                }
                </tbody>
            </table>
        </div>
    )
}