import React from 'react'
import Typography from "@mui/material/Typography";
import SearchIcon from "@mui/icons-material/Search";
import CircularProgress from '@mui/material/CircularProgress';

import {useMediaQuery} from "react-responsive";
import {mediaQueryForMobile} from "../../HomePage/HomePage";
import {useCRMListDealsContext} from "../../../contexts/CRMListDealsContext";

import Button from "@mui/material/Button";
import {LEASE_DEAL_LIGHT} from "../../../contexts/DealContext";
import {useModalContext} from "../../../contexts/ModalContext";


export const CRMListDealsPageHeader = () => {
    const isTabletOrMobile = useMediaQuery({ query: mediaQueryForMobile })
    const { searchValue, setSearchValue, fetchListDealsResponse } = useCRMListDealsContext()

    return (
        <div
            className={'ListDealsPage_header'}
            style={{
                flexDirection: isTabletOrMobile ? 'column' : 'row'
            }}
        >
            <div className={'ListDealsPage_header-search-container'}>
                <input
                    className=""
                    type="text"
                    value={searchValue}
                    placeholder={'Keyword search a car'}
                    onChange={(e) => setSearchValue(e.target.value)}
                />
                {
                    fetchListDealsResponse?.loading ?
                        <CircularProgress size={20} sx={{ color: "#666666"}}/> :
                        <SearchIcon/>
                }
            </div>
        </div>
    )
}