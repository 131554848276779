import React from "react";
import Stack from "@mui/material/Stack";
import {useMediaQuery} from "react-responsive";
import classnames from "classnames";
import {mediaQueryForMobile} from "../HomePage/HomePage";
import {buildTrimAndStyleDescriptionName} from "../../utils/utils";
import Typography from "@mui/material/Typography";

import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import {useDealContext} from "../../contexts/DealContext";
import {PopoverInput} from "../../components/PopoverInput";
import EditLocationIcon from "@mui/icons-material/EditLocation";
import {useModalContext} from "../../contexts/ModalContext";
import Tooltip from "@mui/material/Tooltip";

export const DealPageInfo = () => {
    const isTabletOrMobile = useMediaQuery({ query: mediaQueryForMobile })
    const { updateDealResponse, dealState, setDealStateWithoutQuoteUpdate, setDealStateWithUpdate } = useDealContext()
    const { setIsLocationModalOpen } = useModalContext()

    const car = dealState.car_detail
    const isLoading = updateDealResponse?.loading
    const isPurchase = dealState.is_purchase

    return (
        <Stack
            className={'DealPageInfo_box-container'}
            direction={isTabletOrMobile ? 'column' : 'row'}
            justifyContent="space-between"
            alignItems="center"
        >
            <Stack
                className={'DealPageInfo_box'}
                direction="column"
                justifyContent="space-between"
                alignItems="start"
                style={{
                    marginRight: '20px'
                }}
            >
                <Typography
                    variant="h5"
                    component="div"
                    className={'header-title'}
                    sx={{
                        fontSize: '24px',
                        fontWeight: 'bold',
                        // marginBottom: '10px',
                    }}
                >
                    { car.year } { car.make } { car.model }
                    <Typography
                        variant="subtitle1"
                        component="div"
                        className={'header-subtitle'}
                    >
                        { buildTrimAndStyleDescriptionName(car.trim, car.style_description) }

                    </Typography>
                    <Typography
                        component="span"
                        sx={{
                            fontSize: '12px',
                        }}
                    >
                        <span>Vin: </span>
                        <input
                            className=""
                            type="text"
                            value={dealState.vin}
                            placeholder={'Add Vin'}
                            onChange={(e) => setDealStateWithoutQuoteUpdate({...dealState, vin: e.target.value})}
                        />
                    </Typography>
                    <Divider className={'divider'} sx={{ marginBottom: '10px'}}/>
                </Typography>

                <PopoverInput
                    inputProps={{
                        autoFocus: false,
                        placeholder: isLoading ? '' : 'Description',
                        className: 'DealPageInfo_input'
                    }}
                    spellCheck={!isLoading}
                    className={classnames('DealPageInfo_description', {
                        loading: isLoading
                    })}
                    id="txtArea"
                    rows="8"
                    cols="40"
                    onChange={(text) => setDealStateWithUpdate({description: text})}
                    onEnter={(text) => {
                        if (!isLoading) {
                            setDealStateWithoutQuoteUpdate({description: text})
                        }
                    }}
                    value={dealState?.description || ''}
                    placeholder={isLoading ? '' : 'Description'}
                />
            </Stack>

            <Box className={classnames('DealPageInfo_box', {
                    isPurchase: isPurchase
                })}>
                    <Typography
                        gutterBottom
                        variant="h6"
                        component="div"
                        className={classnames('header location-info', {
                            isPurchase: isPurchase
                        })}
                        sx={{
                            fontSize: '24px',
                            fontWeight: 'bold',
                            marginBottom: '10px',
                        }}
                    >
                        <span>Location</span>
                        <Tooltip title="Edit Location" arrow placement={'top'}>
                            <EditLocationIcon
                                className={'action-icon'}
                                onClick={() => setIsLocationModalOpen(true)}
                            />
                        </Tooltip>
                    </Typography>
                    <Divider className={'divider'}/>
                    <Typography
                        sx={{
                            fontSize: '16px',
                            marginTop: '10px',
                            color: '#666'
                        }}
                    >
                        { dealState.city }, {' '}
                        { dealState.county }, {' '}
                        { dealState.state }
                    </Typography>
                </Box>
        </Stack>
    )
}