import React, { useRef } from 'react';

import {useDealCRMContext} from "../../../contexts/DealCRMPageContext";
import {DealCRMLeadsTableHeaders} from "./DealCRMLeadsTableHeaders";
import {DealPageCRMLeadItem} from "./DealPageCRMLeadItem";
import CustomInfiniteScroll from "../../../components/CustomInfiniteScroll";

export const DealCRMLeadsTable = () => {
    const { listLeadsState, shouldFetchNextLeads, fetchNextLeads } = useDealCRMContext()

    const leads = listLeadsState.results

    return (
        <div className={'parent-scroll-ref'} id={"DealCRMLeadsTableParentScrollRef"}>
            <table className={'LeadsTable'}>
                <DealCRMLeadsTableHeaders/>
                <CustomInfiniteScroll
                    // initialLoad={Boolean(!leads.length)}
                    useWindow={false}
                    pageStart={0}
                    loadMore={() => shouldFetchNextLeads() && fetchNextLeads()}
                    hasMore={listLeadsState.has_more}
                    element={'tbody'}
                    className={'LeadsTable-container'}
                    getScrollParent={() => document.getElementById("DealCRMLeadsTableParentScrollRef")}
                >
                    {leads.map((lead, idx) => (
                        <DealPageCRMLeadItem key={lead.id} lead={lead}/>
                    ))}
                </CustomInfiniteScroll>
            </table>
        </div>
    )
}