import React, {createContext, useContext, useEffect, useState} from 'react';
import {useFetchHook} from "../hooks/useFetchHook";
import fetcher from "../fetcher";
import useDidMountEffect from "../hooks/useDidMountEffect";

const initialPaginationResponse = {
    count: 0,
    next: null,
    previous: null,
    results: [],
    has_more: false,
    current_page: 0
}

export const CRMContext = createContext(undefined)

export const CRMContextProvider = ( { children } ) => {
    // Leads
    const [listLeadsState, setListLeadsState] = useState(initialPaginationResponse)
    const [fetchListLeadsResponse, fetchListLeads] = useFetchHook(fetcher.getLeads)
    const [createLeadResponse, createLead] = useFetchHook(fetcher.createLead)
    const [updateLeadResponse, updateLead] = useFetchHook(fetcher.updateLead)
    const [deleteLeadResponse, deleteLead] = useFetchHook(fetcher.deleteLead)
    const [bulkUploadLeadsResponse, bulkUploadLeads] = useFetchHook(fetcher.bulkUploadLeads)
    const [leadsSearchValue, setLeadsSearchValue] = useState('')

    const [listClientsState, setListClientsState] = useState(initialPaginationResponse)
    const [fetchListClientsResponse, fetchListClients] = useFetchHook(fetcher.getClients)
    const [createClientResponse, createClient] = useFetchHook(fetcher.createClient)
    const [updateClientResponse, updateClient] = useFetchHook(fetcher.updateClient)
    const [deleteClientResponse, deleteClient] = useFetchHook(fetcher.deleteClient)
    const [bulkUploadClientsResponse, bulkUploadClients] = useFetchHook(fetcher.bulkUploadClients)
    const [clientsSearchValue, setClientsSearchValue] = useState('')

    const [deletedLeadIdsState, setDeletedLeadIds] = useState([])
    const [deletedClientIdsState, setDeletedClientIds] = useState([])

    const [currentMonth, setCurrentMonth] = useState(new Date().getMonth())
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear())

    const shouldFetchNextLeads = () => {
        const data = fetchListLeadsResponse?.data?.data
        if (!data?.has_more) return false;
        if (fetchListLeadsResponse?.loading) return false;

        return true
    }

    const fetchNextLeads = () => {
        let queryParams = new URLSearchParams({
            month: currentMonth,
            year: currentYear
        })

        if (listLeadsState.current_page !== 0) {
            queryParams.append("page", listLeadsState.current_page + 1)
        }

        if (leadsSearchValue.length) {
            queryParams.append("s", leadsSearchValue)
        }

        return fetchListLeads(queryParams)
    }

    const removeDeletedLeadFromList = (id) => {
        const newList = listLeadsState.results.filter((lead) => lead.id !== id)
        const newDeletedIds = deletedLeadIdsState
        newDeletedIds.push(id)
        setDeletedLeadIds(newDeletedIds)
        setListLeadsState({...listLeadsState, results: newList})
    }

    useEffect(function fetchListLeadsFromBackendOnMount(){
        fetchNextLeads()
    }, [])

    useDidMountEffect(function fetchListLeadsOnMonthOrYearChange(){
        let queryParams = new URLSearchParams({
            month: currentMonth,
            year: currentYear,
        })

        if (leadsSearchValue.length) {
            queryParams.append("s", leadsSearchValue)
        }

        return fetchListLeads(queryParams)
    }, [currentMonth, currentYear])

    useDidMountEffect(function updateListLeadsFromBackend(){
        const response = fetchListLeadsResponse?.data?.data

        if (response) {
            const currentPage = response.current_page
            if (currentPage === 1) {
                setListLeadsState(fetchListLeadsResponse?.data?.data)
            } else {
                if (currentPage !== listLeadsState.current_page) {
                    const currentList = listLeadsState.results
                    const data = fetchListLeadsResponse?.data?.data

                    const newList = currentList.concat(data.results)
                    setListLeadsState({...data, results: newList})

                }
            }
        }

    }, [fetchListLeadsResponse])

    useEffect(function addCreatedLeadToList() {
        if (createLeadResponse?.data?.data) {
            const newList = structuredClone(listLeadsState.results)
            newList.unshift(createLeadResponse?.data?.data)
            setListLeadsState({...listLeadsState, results: newList})
        }
    }, [createLeadResponse])

    useEffect(function updateListAfterDelete() {
        if (deleteLeadResponse?.data?.data?.id) {
            removeDeletedLeadFromList(deleteLeadResponse?.data?.data?.id)
        }
    }, [deleteLeadResponse])

    const fetchNextClients = () => {
        let queryParams = new URLSearchParams({
            month: currentMonth,
            year: currentYear,
        })

        if (listClientsState.current_page !== 0) {
            queryParams.append("page", listClientsState.current_page + 1)
        }

        if (clientsSearchValue.length) {
            queryParams.append("s", clientsSearchValue)
        }

        return fetchListClients(queryParams.toString())
    }

    const shouldFetchNextClients = () => {
        const data = fetchListClientsResponse?.data?.data
        if (!data?.has_more) return false;
        if (fetchListClientsResponse?.loading) return false;

        return true
    }

    const removeDeletedClientFromList = (id) => {
        const newList = listClientsState.results.filter((client) => client.id !== id)
        const newDeletedIds = deletedClientIdsState
        newDeletedIds.push(id)
        setDeletedClientIds(newDeletedIds)
        setListClientsState({...listClientsState, results: newList})
    }

    useEffect(function fetchListClientsFromBackendOnMount(){
        fetchNextClients()
    }, [])

    useDidMountEffect(function fetchListClientsOnMonthChange(){
        let queryParams = new URLSearchParams({
            month: currentMonth,
            year: currentYear,
        })

        if (leadsSearchValue.length) {
            queryParams.append("s", clientsSearchValue)
        }

        return fetchListClients(queryParams)
    }, [currentMonth, currentYear])

    useDidMountEffect(function updateListClientsFromBackend(){
        const response = fetchListClientsResponse?.data?.data

        if (response) {
            const currentPage = response.current_page
            if (currentPage === 1) {
                setListClientsState(fetchListClientsResponse?.data?.data)
            } else {
                if (currentPage !== listClientsState.current_page) {
                    const currentList = listClientsState.results
                    const data = fetchListClientsResponse?.data?.data

                    const newList = currentList.concat(data.results)
                    setListClientsState({...data, results: newList})

                }
            }
        }

    }, [fetchListClientsResponse])

    useEffect(function addCreatedClientToList() {
        if (createClientResponse?.data?.data) {
            const newList = structuredClone(listClientsState.results)
            newList.unshift(createClientResponse?.data?.data)
            setListClientsState({...listClientsState, results: newList})
        }
    }, [createClientResponse])

    useEffect(function updateClientListAfterDelete() {
        if (deleteClientResponse?.data?.data?.id) {
            removeDeletedClientFromList(deleteClientResponse?.data?.data?.id)
        }
    }, [deleteClientResponse])


    const value = {
        // Leads
        listLeadsState,
        fetchNextLeads,
        shouldFetchNextLeads,

        fetchListLeads,
        fetchListLeadsResponse,

        createLead,
        createLeadResponse,

        updateLead,
        updateLeadResponse,

        deleteLead,
        deleteLeadResponse,

        bulkUploadLeads,
        bulkUploadLeadsResponse,

        leadsSearchValue,
        setLeadsSearchValue,

        removeDeletedLeadFromList,

        // Clients

        listClientsState,
        fetchNextClients,
        shouldFetchNextClients,

        fetchListClients,
        fetchListClientsResponse,

        createClient,
        createClientResponse,

        updateClient,
        updateClientResponse,

        deleteClient,
        deleteClientResponse,

        bulkUploadClients,
        bulkUploadClientsResponse,

        clientsSearchValue,
        setClientsSearchValue,

        currentMonth,
        setCurrentMonth,
        currentYear,
        setCurrentYear,
    }

    return (
        <CRMContext.Provider value={value}>
            { children }
        </CRMContext.Provider>
    )
}

export const useCRMContext = () => {
    const context = useContext(CRMContext)

    if (context === undefined) {
        throw new Error('useCRMContext must be used within CRMContext')
    }
    return context
}