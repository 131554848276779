import React from 'react'

import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import {buildStyleDescriptionName} from "../../utils/utils";
import {useCalcContext} from "../../contexts/CalcContext";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CircularProgress from '@mui/material/CircularProgress';
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import Divider from "@mui/material/Divider";



export const CalcSelectCar = () => {
    const {
        carState,
        setCarState,
        getMakesForYearResponse,
        getModelsForMakeResponse,
        getTrimsForModelResponse,
        calcState,
        setCalcState,
    } = useCalcContext()

    const getIcon = (isLoading, props) => {
        return isLoading ? <CircularProgress {...props} size={13} style={{marginRight: '6px', marginTop: '3px'}}/> :
            <ArrowDropDownIcon {...props}/>
    }

    const renderTrim = (trim) => {
        return (
            <MenuItem key={`${trim.Trim}-${trim.StyleDescription}`} value={trim}>
                <div className={'trim-container'}>
                    <div className={'trim-name'}>{trim.Trim}</div>
                    <div className={'sd-name'}>
                        {buildStyleDescriptionName(trim.Trim, trim.StyleDescription)}
                    </div>
                </div>
            </MenuItem>
        )
    }

    const { makes, models, trims } = carState

    return (
        <Box>
            <Typography
                variant="h5"
                component="div"
                className={'header-title'}
                sx={{
                    fontSize: '24px',
                    fontWeight: 'bold',
                    // marginBottom: '10px',
                }}
            >
                <div className={'select-row'}>
                    <label>Year</label>
                    <Select
                        className={'Select'}
                        labelId="pricing-select-year"
                        id="year-select"
                        value={carState.year}
                        label="Year"
                        onChange={(e) => {
                            const year = e.target.value
                            setCarState({
                                ...carState,
                                year: year,
                                make: '',
                                model: '',
                                trim: '',
                            })
                        }}
                    >
                        <MenuItem value={'2023'}>2023</MenuItem>
                        <MenuItem value={'2024'}>2024</MenuItem>
                        <MenuItem value={'2025'}>2025</MenuItem>
                        <MenuItem value={'2026'}>2026</MenuItem>
                    </Select>
                </div>
                <div className={'select-row'}>
                    <label>Make</label>
                    <Select
                        className={'Select'}
                        IconComponent={(props) => getIcon(getMakesForYearResponse?.loading, props)}
                        value={carState.make}
                        disabled={!carState.year || ! carState.makes.length}
                        onChange={(e) => {
                            const make = e.target.value
                            setCarState({
                                ...carState,
                                make: make,
                                model: '',
                                trim: '',
                            })
                        }}
                    >
                        {
                            makes.map((make) => <MenuItem key={make.Make} value={make.Make}>{make.Make}</MenuItem>)
                        }
                    </Select>
                </div>
                <div className={'select-row'}>
                    <label>Model</label>
                    <Select
                        className={'Select'}
                        IconComponent={(props) => getIcon(getModelsForMakeResponse?.loading, props)}
                        value={carState.model}
                        disabled={!carState.make || ! carState.models.length}
                        onChange={(e) => {
                            const model = e.target.value
                            setCarState({
                                ...carState,
                                model: model,
                                trim: ''
                            })
                        }}
                    >
                        {
                            models?.map((model) => <MenuItem key={model.Model} value={model.Model}>{model.Model}</MenuItem>)
                        }
                    </Select>
                </div>
                <div className={'select-row'}>
                    <label>Trim</label>
                    <Select
                        className={'Select'}
                        value={carState.trim}
                        disabled={!carState.model|| ! carState.trims.length}
                        IconComponent={(props) => getIcon(getTrimsForModelResponse?.loading, props)}
                        onChange={(e) => {
                            const trim = e.target.value

                            setCarState({
                                ...carState,
                                trim: trim,
                                style_description: trim.StyleDescription,
                            })
                        }}
                    >
                        {
                            trims.map(trim => renderTrim(trim))
                        }
                    </Select>
                </div>

                {
                    carState.vin_required &&
                    <React.Fragment>
                        <Divider
                            sx={{
                                margin: '10px 0px',
                                fontSize: '10px'
                            }}
                        >
                            A Vin is required for this vehicle
                        </Divider>
                        <div className={'select-row'}>
                            <label>Vin</label>
                            <input
                                className=""
                                type="text"
                                value={calcState.vin}
                                placeholder={'Enter a Vin'}
                                onChange={(e) => setCalcState({...calcState, vin: e.target.value})}
                            />
                        </div>
                    </React.Fragment>
                }
            </Typography>
        </Box>
    )
}