import React, { useEffect, useState } from 'react'
import fetcher from "../../../fetcher";
import Stack from "@mui/material/Stack";
import {useMediaQuery} from "react-responsive";
import {mediaQueryForMobile} from "../../HomePage/HomePage";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import {useDealCRMContext} from "../../../contexts/DealCRMPageContext";
import {useFetchResource} from "../../../hooks/useFetchResource";

export const MiniClientItem = ({ clientId }) => {
    const isTabletOrMobile = useMediaQuery({ query: mediaQueryForMobile })
    const [clientState, setClientState] = useFetchResource(fetcher.getClient, clientId)
    const { selectedClients, setSelectedClients }  = useDealCRMContext()

    const toggleSelected = () => {
        let newList = structuredClone(selectedClients)
        if (selectedClients.includes(clientId)) {
            newList = newList.filter(id => id !== clientId)
        } else {
            newList = [...newList, clientId]
        }
        setSelectedClients([...newList])
    }

    if (!clientState) return null;

    return (
        <Stack
            direction={isTabletOrMobile ? 'column' : 'row'}
            alignItems="center"
            className={'MiniLeadItem'}
        >
            <CancelOutlinedIcon
                onClick={(e) => {
                    e.stopPropagation()
                    toggleSelected()
                }}
                className={'action-icon'}
            />
            <div>
                <div>{clientState.name}</div>
                <div>{clientState.email}</div>
            </div>
        </Stack>
    )
}