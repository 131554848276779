import {useState} from 'react';
import {useSearchParams} from "react-router-dom";
import {useDebounceEffect} from "./useDebounceEffect";
import useTraceUpdate from "./useTraceUpdate";
import useDidMountEffect from "./useDidMountEffect";


const useDebouncedSearchParams = (func, deps = [], debounce = 300) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [searchValue, setSearchValue] = useState(searchParams.get('') ||'')

    useDebounceEffect(function setSearchParamsBasedOnSearchValue(){
        if ((searchParams.get('s') || '') !== searchValue) {
            if (searchValue.length) {
                setSearchParams({s: searchValue})
            } else {
                setSearchParams()
            }
        }
        return () => setSearchParams()
    }, [...deps, searchValue, setSearchParams], debounce)

    useDidMountEffect(() => {
        func()
    }, [...deps, searchParams]);

    return {searchValue, setSearchValue, searchParams}
}

export default useDebouncedSearchParams;