import React, {useState} from "react";
import Stack from "@mui/material/Stack";
import {useMediaQuery} from "react-responsive";
import classnames from "classnames";
import {mediaQueryForMobile} from "../HomePage/HomePage";
import Typography from "@mui/material/Typography";

import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import {useModalContext} from "../../contexts/ModalContext";
import Tooltip from "@mui/material/Tooltip";
import {CalcSelectCar} from "./CalcSelectCar";
import {LEASE_DEAL_LIGHT, PURCHASE_DEAL_LIGHT} from "../../contexts/DealContext";
import CustomCurrencyInput from "../../components/CustomCurrencyInput";
import Button from "@mui/material/Button";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import {useCalcContext} from "../../contexts/CalcContext";
import useEnterEffect from "../../hooks/useEnterEffect";
import {useLocation} from "react-router-dom";
import {CalcPageSelectLocation} from "./CalcPageSelectLocation";
import _ from "lodash";
import {CalcPagePricing} from "./CalcPagePricing";


export const CalcPageInfo = () => {
    const isTabletOrMobile = useMediaQuery({ query: mediaQueryForMobile })
    let location = useLocation();
    const [isCreatingLease, setIsCreatingLease] = useState(false)
    const [isCreatingPurchase, setIsCreatingPurchase] = useState(false)
    const {
        calcState,
        getLeadId
    } = useCalcContext()

    const {
        setIsDealModalOpen,
        setIsDeskingModalOpen,
        isDealModalOpen
    } = useModalContext()

    const onCreate = (newLeaseData) => {
        if (getLeadId()) {
            window.onunload = function() {
                if (window.opener && !window.opener.closed) {
                    window.opener.onLeadCreate(newLeaseData);
                }
            };
            window.close();
        } else {
            setIsDealModalOpen({id: newLeaseData.id})
        }
    }

    const createNewLease = () => {
        setIsCreatingLease(true)

        const deskingData = structuredClone(calcState)
        delete deskingData.location
        delete deskingData.location_id

        deskingData.is_purchase = false

        setIsCreatingLease(false)

        setIsDeskingModalOpen(
            {
                onSave: (data) =>  onCreate(data),
                isDeskingContext: false,
                ...deskingData
            }
        )
    }

    const createNewPurchase = (isOnePay) => {
        setIsCreatingPurchase(true)
        let deskingData = structuredClone(calcState)
        delete deskingData.location
        delete deskingData.location_id

        deskingData.is_purchase = true
        deskingData.is_one_pay = isOnePay
        setIsCreatingPurchase(false)
        setIsDeskingModalOpen(
            {
                onSave: (data) =>  onCreate(data),
                isDeskingContext: false,
                ...deskingData
            }
        )
    }

    const isCalculatePath = location.pathname.split('/')[1] === 'calculate'

    useEnterEffect(() => {
        if (
            calcState.car &&
            calcState.msrp &&
            calcState.cost &&
            !isDealModalOpen &&
            isCalculatePath
        ) {
            createNewLease()
        }
    }, [calcState, isDealModalOpen])

    return (
        <Stack
            style={{ width: '100%' }}
            direction={isTabletOrMobile ? 'column' : 'row'}
            alignItems="center"
        >
            <Box className={'CalcPageInfo_box'}
            >
                <Typography
                    gutterBottom
                    variant="h6"
                    component="div"
                    className={'header location-info'}
                    sx={{
                        fontSize: '24px',
                        fontWeight: 'bold',
                        marginBottom: '10px',
                    }}
                >
                    <span>Select Car</span>
                </Typography>
                <Divider className={'divider'} sx={{ marginBottom: '10px'}}/>
                <CalcSelectCar/>
            </Box>
            <Box className={'CalcPageInfo_box'}>
                <CalcPageSelectLocation />
            </Box>
            <Box className={classnames("CalcPageInfo_box", {
                mobile: isTabletOrMobile
            })}>
                <CalcPagePricing
                    createNewLease={createNewLease}
                    createNewPurchase={createNewPurchase}
                />
            </Box>
        </Stack>
    )
}