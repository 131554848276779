import React from "react";
import {useAuthContext} from "../../contexts/AuthContext";
import {AppSidebarNav} from "./AppSidebarNav";
import {Outlet} from "react-router-dom";

import {Navigate, useLocation} from "react-router";
import Box from "@mui/material/Box";
import {useUserContext} from "../../contexts/UserContext";
import './HomePage.scss'
import useDidMountEffect from "../../hooks/useDidMountEffect";

import { useMediaQuery } from 'react-responsive'
import {useUIContext} from "../../contexts/UIContext";
import {AppMobileNav} from "./AppMobileNav";
import {DealModal} from "../PricingListPage/DealModal";
import {useModalContext} from "../../contexts/ModalContext";
import {DeskingModal} from "../PricingListPage/DeskingModal";
import {CustomerPDFModal} from "../DealPage/CustomerPDFModal";
import {DealerPDFModal} from "../DealPage/DealerPDFModal";
import {CRMListDealsModal} from "../CRM/CRMListDealsPage/CRMListDealsModal";
import {DealCRMModal} from "../DealPage/DealCRMPage/DealCRMModal";
import {ConvertToClientModal} from "../CRM/LeadsPage/ConvertToClientModal";
import Stack from "@mui/material/Stack";

export const mediaQueryForMobile = '(max-width: 1224px)'

export const HomePage = () => {
    const { isAuthenticated } = useAuthContext()
    const { updateProfile, profileState } = useUserContext()
    const { uiState } = useUIContext()
    let location = useLocation();

    const {
        isDealModalOpen,
        setIsDealModalOpen,
        isDeskingModalOpen,
        setIsDeskingModalOpen,
        isCustomerPDFModalOpen,
        setIsCustomerPDFModalOpen,
        isDealerPDFModalOpen,
        setIsDealerPDFModalOpen,
        isCRMListDealsModalOpen,
        setIsCRMListDealsModalOpen,
        isDealCRMModalOpen,
        setIsDealCRMModalOpen,
        isConvertToClientModalOpen,
        setIsConvertToClientModalOpen
    } = useModalContext()

    const isTabletOrMobile = useMediaQuery({ query: mediaQueryForMobile })

    useDidMountEffect(function updatePagesVisited(){
        const pagesVisited = profileState?.pages_visited
        if (pagesVisited) {
            const pageVisited = location.pathname
            const lastPageVisited = pagesVisited[0]
            if (pageVisited !== lastPageVisited) {
                let newPagesVisited = structuredClone(profileState.pages_visited)
                newPagesVisited.unshift(pageVisited)
                newPagesVisited = newPagesVisited.slice(0,20)
                updateProfile({...profileState, pages_visited: newPagesVisited})
            }
        }
    }, [location]);

    if (!isAuthenticated) {
        return <Navigate to={'/login'} replace={true}/>
    }

    const {sideNavDrawerWidth} = uiState

    const isStaging = process.env.REACT_APP_ENV === 'staging'

    return (
        <div
            style={{ flexDirection: isTabletOrMobile ? 'column' : 'row'}}
            id={"HomePage"}
        >
            {
                isStaging &&
                <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    className={'staging-warning'}
                >
                    <span>Welcome to Staging where nothing matters</span>
                </Stack>
            }
            <Box
                style={{
                    width: isTabletOrMobile ? '100%' : `${sideNavDrawerWidth}px`
                }}
            >
                {
                    isTabletOrMobile ? <AppMobileNav/> : <AppSidebarNav />
                }
            </Box>
            <Box
                component="main"
                style={{
                    flexGrow: 1,
                    width: isTabletOrMobile ? '100%' : `calc(100% - ${sideNavDrawerWidth}px)`,
                    marginTop: isTabletOrMobile ? '56px' : '',
                }}
            >
                <Outlet/>
            </Box>

            {
                isDealModalOpen &&
                <DealModal
                    isOpen={Boolean(isDealModalOpen)}
                    setIsOpen={setIsDealModalOpen}
                    id={isDealModalOpen.id}
                    {...isDealModalOpen}
                />
            }
            {
                isDeskingModalOpen &&
                <DeskingModal
                    isOpen={Boolean(isDeskingModalOpen)}
                    setIsOpen={setIsDeskingModalOpen}
                    id={isDeskingModalOpen.id}
                    {...isDeskingModalOpen}
                />
            }
            {
                isCustomerPDFModalOpen &&
                <CustomerPDFModal
                    isOpen={Boolean(isCustomerPDFModalOpen)}
                    setIsOpen={setIsCustomerPDFModalOpen}
                    {...isCustomerPDFModalOpen}
                />
            }
            {
                isDealerPDFModalOpen &&
                <DealerPDFModal
                    isOpen={Boolean(isDealerPDFModalOpen)}
                    setIsOpen={setIsDealerPDFModalOpen}
                    {...isDealerPDFModalOpen}
                />
            }
            {
                isCRMListDealsModalOpen &&
                <CRMListDealsModal
                    isOpen={Boolean(isCRMListDealsModalOpen)}
                    setIsOpen={setIsCRMListDealsModalOpen}
                    {...isCRMListDealsModalOpen}
                />
            }
            {
                isDealCRMModalOpen &&
                <DealCRMModal
                    isOpen={Boolean(isDealCRMModalOpen)}
                    setIsOpen={setIsDealCRMModalOpen}
                    {...isDealCRMModalOpen}
                />
            }
            {
                isConvertToClientModalOpen &&
                <ConvertToClientModal
                    isOpen={Boolean(isConvertToClientModalOpen)}
                    setIsOpen={setIsConvertToClientModalOpen}
                    {...isConvertToClientModalOpen}
                />
            }
        </div>
    )
}
