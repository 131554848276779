import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from '@mui/icons-material/Close';

import {DealPage} from "../DealPage/DealPage";
import {DealContextProvider} from "../../contexts/DealContext";
import {useMediaQuery} from "react-responsive";
import {mediaQueryForMobile} from "../HomePage/HomePage";



export const DealModal = ( { id, isOpen, setIsOpen, ...props } ) => {
    const isTabletOrMobile = useMediaQuery({ query: mediaQueryForMobile })

    const style = {
        position: 'absolute',
        top: isTabletOrMobile ? '56%' : '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        background: 'white',
        boxShadow: 24,
        p: 4,
        height: '100vh',
        overflow: 'scroll'
    };

    return (
        <Modal
            open={isOpen}
            onClose={() => setIsOpen(null)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box style={style}>
                <div style={{ position: 'relative' }}>
                    {
                        isTabletOrMobile &&
                            <span
                                onClick={() => setIsOpen(null)}
                                style={{
                                    position: 'absolute',
                                    top: '5px',
                                    left: '27px',
                                    display: 'flex',
                                    fontWeight: 'bold',
                                    fontSize: '16px'
                                }}
                            >
                                <CloseIcon/> Close
                            </span>
                    }
                    <DealContextProvider id={id} {...props}>
                        <DealPage isModal={true} {...props}/>
                    </DealContextProvider>
                </div>
            </Box>
        </Modal>
    )
}