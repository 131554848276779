import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

import CloseIcon from "@mui/icons-material/Close";
import {useMediaQuery} from "react-responsive";
import {mediaQueryForMobile} from "../../HomePage/HomePage";
import {ConvertToClientPage} from "./ConvertToClientPage";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: "#FFFFFF",
    boxShadow: "none",
    borderRadius: "8px",
    outline: "none",
}


export const ConvertToClientModal = ( { id, isOpen, setIsOpen, ...props } ) => {
    const isTabletOrMobile = useMediaQuery({ query: mediaQueryForMobile })

    return (
        <Modal
            open={isOpen}
            onClose={() => setIsOpen(null)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                style={style}
                className={'ConvertToClientModal'}
            >
                {
                    isTabletOrMobile &&
                    <span
                        onClick={() => setIsOpen(null)}
                        style={{
                            position: 'absolute',
                            top: '5px',
                            left: '27px',
                            display: 'flex',
                            fontWeight: 'bold',
                            fontSize: '16px'
                        }}
                    >
                                <CloseIcon/> Close
                            </span>
                }
                <ConvertToClientPage {...props}/>
            </Box>
        </Modal>
    )
}