import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

import CloseIcon from "@mui/icons-material/Close";
import {useMediaQuery} from "react-responsive";
import {mediaQueryForMobile} from "../../HomePage/HomePage";
import {DealCRMPage} from "./DealCRMPage";
import {DealCRMContextProvider} from "../../../contexts/DealCRMPageContext";

const style = {
    position: 'absolute',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    top: '50%',
    boxShadow: 24,
    zIndex: '1400',
    outline: 'none',
    borderRadius: '6px',
    width: '90vw'
};

export const DealCRMModal = ({ id, isOpen, setIsOpen, ...props } ) => {
    const isTabletOrMobile = useMediaQuery({ query: mediaQueryForMobile })

    return (
        <Modal
            open={isOpen}
            onClose={() => setIsOpen(null)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box className={'CRMListDealsModal'} style={style}>
                {
                    isTabletOrMobile &&
                    <span
                        onClick={() => setIsOpen(null)}
                        style={{
                            position: 'absolute',
                            top: '5px',
                            left: '27px',
                            display: 'flex',
                            fontWeight: 'bold',
                            fontSize: '16px',
                        }}
                    >
                                <CloseIcon/> Close
                            </span>
                }
                <DealCRMContextProvider {...props}>
                    <DealCRMPage/>
                </DealCRMContextProvider>
            </Box>
        </Modal>
    )
}