import React from "react";
import {useDealContext} from "../../contexts/DealContext";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import CurrencyInput from "react-currency-input-field";
import Checkbox from "@mui/material/Checkbox";
import FeeHelper from "../../utils/FeeHelper";
import {mediaQueryForMobile} from "../HomePage/HomePage";
import {useMediaQuery} from "react-responsive";
import useFeeStateHook from "../../hooks/useFeeStateHook";

export const CustomDealPageRebateItem = ({ fee }) => {
    const isTabletOrMobile = useMediaQuery({ query: mediaQueryForMobile })
    const { updateDealResponse } = useDealContext()
    const [rebateState, setRebateState] = useFeeStateHook(fee)

    const feeHelper = new FeeHelper(rebateState)
    const selected = feeHelper.isSelected()

    const isLoading = updateDealResponse?.loading

    return (
        <TableRow key={feeHelper.uniqueId()}>
            <TableCell
                align="left"
                width={isTabletOrMobile ? '10%' : '25%'}
            >
                {feeHelper.name()}
            </TableCell>
            <TableCell
                align="right"
                width={isTabletOrMobile ? '5%' : '25%'}
            >
                <CurrencyInput
                    className={'payment-row_value disabled'}
                    prefix="$"
                    value={feeHelper.absoluteAmount()}
                    disabled={!rebateState.Name}
                />
            </TableCell>
            <TableCell
                align="right"
                width={isTabletOrMobile ? '5%' : '10%'}
            >
                <Checkbox
                    className={'payment-row_value'}
                    onChange={(e) => {
                        const newType = selected ? 'PaidOutsideOfClosing' : 'Financed'
                        const newDescriptionType = selected ? 'RegularFee' : 'Rebate'
                        setRebateState({...rebateState, Type: newType, DescriptionType: newDescriptionType})
                    }}
                    checked={selected}
                    disabled={isLoading}
                />
            </TableCell>
            <TableCell
                align="right"
                width={isTabletOrMobile ? '5%' : '10%'}
            >
                <Checkbox
                    className={'payment-row_value'}
                    onChange={() => {
                        const newTaxIndex = rebateState.TaxIndex === 0 ? -1 : 0
                        setRebateState({...rebateState, TaxIndex: newTaxIndex})
                    }}
                    checked={rebateState && rebateState?.TaxIndex !== 0}
                    disabled={!selected}
                />
            </TableCell>
        </TableRow>
    )
}