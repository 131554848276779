import React from 'react'
import Typography from "@mui/material/Typography";
import SearchIcon from "@mui/icons-material/Search";
import CircularProgress from '@mui/material/CircularProgress';

import {useMediaQuery} from "react-responsive";
import {mediaQueryForMobile} from "../../HomePage/HomePage";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {useLocation, useNavigate} from "react-router";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import {useModalContext} from "../../../contexts/ModalContext";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import {useCRMContext} from "../../../contexts/CRMContext";
import {useDebounce} from "@uidotdev/usehooks";

export const LeadsPageHeader = () => {
    const isTabletOrMobile = useMediaQuery({ query: mediaQueryForMobile })
    const { setIsUploadCSVModalOpen } = useModalContext()
    const { fetchListLeads, updateLeadResponse, leadsSearchValue, setLeadsSearchValue, bulkUploadLeads, currentMonth, currentYear } = useCRMContext()

    const debouncedSearch = useDebounce(leadsSearchValue, 300)

    const navigate = useNavigate();
    const location = useLocation();

    useDidMountEffect(function fetchListLeadsWhenSearchParamsChange(){
        let queryParams = new URLSearchParams({
            month: currentMonth,
            year: currentYear,
        })
        if (leadsSearchValue.length) {
            queryParams.append("s", leadsSearchValue)
            fetchListLeads(queryParams.toString())
        } else {
            fetchListLeads(queryParams.toString())
        }
    }, [debouncedSearch])

    const isLeadsPath = location.pathname.split('/')[1] === 'leads'

    return (
        <Stack
            id={"CRMHeader"}
            direction={isTabletOrMobile ? 'column' : 'row'}
            alignItems="end"
            justifyContent={'space-between'}
        >
            <Stack
                direction={'row'}
                alignItems="center"
                justifyContent={'start'}
            >
                <Typography
                    sx={{
                        fontSize: '24px',
                        color: '#666'
                    }}
                    component="div"
                >
                    CRM
                </Typography>
                <Typography
                    sx={{
                        fontSize: '12px',
                        color: '#666',
                        marginLeft: '20px',
                    }}
                    component="span"
                >
                    {
                        updateLeadResponse?.loading ? "Saving..." : "Saved"
                    }
                </Typography>

            </Stack>

            <Stack
                // style={{ width: '100%' }}
                direction={isTabletOrMobile ? 'column' : 'row'}
                alignItems="center"
            >
                <Tabs
                    value={location.pathname}
                    onChange={(value, newValue) => {
                        navigate(`${newValue}`)
                    }}
                >
                    <Tab label="Leads" value={'/leads'} />
                    <Tab label="Clients" value={'/clients'}/>
                </Tabs>

                <div className={'CRM_header-search-container'}>
                    <input
                        className=""
                        type="text"
                        value={leadsSearchValue}
                        placeholder={`Search ${isLeadsPath ?  'Leads' : 'Clients'}`}
                        onChange={(e) => setLeadsSearchValue(e.target.value)}
                    />
                    {
                        false ?
                            <CircularProgress size={20} sx={{ color: "#666666"}}/> :
                            <SearchIcon/>
                    }
                </div>
                <Button
                    className={'upload-csv-button'}
                    // style={{ background: LEASE_DEAL_LIGHT }}
                    onClick={() => {
                        setIsUploadCSVModalOpen({
                            bulkUploadFunc: bulkUploadLeads
                        })
                    }}
                >
                    Upload CSV
                </Button>
            </Stack>

        </Stack>
    )
}