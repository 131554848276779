import Typography from "@mui/material/Typography";
import React from "react";

import QuoteHelper from "../../../utils/QuoteHelper";
import classnames from "classnames";

export const CRMListDealMobileCard = ({ deal, setSelectedDealId, isSelected } ) => {
    const car_detail = deal.car_detail
    const quoteHelper = new QuoteHelper(deal)

    return (
        <div
            onClick={() => setSelectedDealId(deal.id)}
            className={classnames('ListDealCard mobile', {
                selected: isSelected
            })}
        >
            <div
                className={'top'}
            >
                <div
                    className={'car-detail'}
                >
                    <Typography
                        sx={{
                            fontSize: '12px',
                            fontWeight: 'bold'
                        }}
                    >
                        {car_detail.year} {car_detail.make} {car_detail.model}
                    </Typography>
                    <Typography
                        component={'span'}
                        sx={{
                            fontWeight: 'bold',
                            fontSize: '10px',
                        }}
                    >
                        ${quoteHelper.basePayment()}
                    </Typography>
                    <Typography
                        component={'span'}
                        sx={{
                            fontSize: '9px',
                        }}
                    >
                        /Month
                    </Typography>
                </div>
            </div>
        </div>
    )
}