import React, {useState} from 'react';

import {useUserContext} from "../../../contexts/UserContext";
import {CreateLeadItem} from "./CreateLeadItem";
import {useDebounce} from "@uidotdev/usehooks";
import useDidMountEffect from "../../../hooks/useDidMountEffect";

export const CustomLeadColumnHeader = ({ column, columnNum }) => {
    const { profileState, setProfileStateWithUpdate } = useUserContext()
    const [columnName, setColumnName] = useState(column['display_name'])
    const debouncedColumn = useDebounce(columnName, 300)

    useDidMountEffect(function updateLeadColumnHeader() {
        const newLeadColumns = structuredClone(profileState.lead_columns)
        newLeadColumns[columnNum]["display_name"] = columnName

        setProfileStateWithUpdate({...profileState, lead_columns: newLeadColumns})
    }, [debouncedColumn])

    return (
        <th
            key={columnNum}
            width={'10%'}
            className={'crm_table_header'}
        >
            <input
                className=""
                type="text"
                value={columnName}
                onChange={(e) => setColumnName(e.target.value)}
            />
        </th>
    )

}

export const LeadsTableHeaders = () => {
    const { profileState } = useUserContext()
    const columns = profileState.lead_columns

    const sortedColumns = Object.keys(columns).sort((a, b) => a - b)

    return (
        <thead>
            <tr className={"leads_table_row"}>
                <th
                    width={'5%'}
                    key='Name' className={'crm_table_header'}>
                    Name
                </th>

                <th
                    width={'3%'}
                    key='Phone' className={'crm_table_header'}>
                    Phone
                </th>
                <th
                    width={'5%'}
                    key='Email' className={'crm_table_header'}>
                    Email
                </th>

                {
                    sortedColumns.map((columnNum) => (
                        <CustomLeadColumnHeader
                            key={columnNum}
                            column={columns[columnNum]}
                            columnNum={columnNum}/>
                        )
                    )
                }

                <th
                    width={'3%'}
                    key='My Quotes' className={'crm_table_header'}>
                    My Quotes
                </th>

                <th
                    width={'1%'}
                    key='Actions'
                    className={'crm_table_header'}>
                    Actions
                </th>

            </tr>
            <CreateLeadItem/>
        </thead>
    )
}