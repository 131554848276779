import React, {useState} from 'react'

import classnames from "classnames";

import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import GroupAddIcon from '@mui/icons-material/GroupAdd';

import Box from "@mui/material/Box";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {Link} from "react-router-dom";
import {useDealContext} from "../../contexts/DealContext";
import {useMediaQuery} from "react-responsive";
import {mediaQueryForMobile} from "../HomePage/HomePage";
import Popover from "@mui/material/Popover";
import {useModalContext} from "../../contexts/ModalContext";
import CircularProgress from '@mui/joy/CircularProgress';
import QuoteHelper from "../../utils/QuoteHelper";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import Tooltip from "@mui/material/Tooltip";
import {useUserContext} from "../../contexts/UserContext";


let usePDF = () => [{url: '', loading: false}, function(){}]

if (process.env.NODE_ENV !== 'test') {
    import("@react-pdf/renderer")
        .then((mod) => {
            usePDF = mod.usePDF
        });
}

const DealHeader = ( { isModal } ) => {
    const isTabletOrMobile = useMediaQuery({ query: mediaQueryForMobile })
    const { isFeatureFlagEnabled } = useUserContext()

    const { setIsDealCRMModalOpen, setIsDeskingModalOpen, setIsDealModalOpen, setIsCustomerPDFModalOpen, setIsDealerPDFModalOpen } = useModalContext()
    const { dealState, dealColorLight, cloneDeal, setDealStateWithoutUpdate, setDealStateWithoutQuoteUpdate } = useDealContext()
    const [isCreatingDeal, setIsCreatingDeal] = useState(false)
    const [anchorEl, setAnchorEl] = React.useState(null);

    const isPurchase = dealState.is_purchase

    const handleClick = (event) => {
        event.stopPropagation()
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const deskClonedDeal = () => {
        const is_purchase = !dealState.is_purchase
        setIsDeskingModalOpen(
            {
                onSave: (data) =>  setIsDealModalOpen({id: data.id}),
                ...dealState,
                is_purchase: is_purchase,
                id: null
            }
        )
    }

    const deskCashPurcahse= () => {
        setIsDeskingModalOpen(
            {
                onSave: (data) =>  setIsDealModalOpen({id: data.id}),
                ...dealState,
                is_purchase: true,
                is_one_pay: true,
                id: null
            }
        )
    }

    const cloneDealAndOpenModal = () => {
        return cloneDeal(dealState).then((data) => {
            setIsDealModalOpen({id: data.data.id})
        })
    }

    const cloneIcon = () => {
        let icon;
        if (isCreatingDeal) {
            icon = <CircularProgress variant="soft" size={'sm'}/>
        } else {
            if (isPurchase) {
                icon = <AddOutlinedIcon className={'isPurchase'}/>
            } else {
                icon = <AccountBalanceIcon/>
            }
        }
        return icon
    }

    if (!dealState) return null;

    const updatedAtDate = new Date(dealState.updated_at)
    const expirationDate = new QuoteHelper(dealState).expirationDate()
    const isExpired = new Date(expirationDate).setUTCHours(0,0,0,0) < new Date().setUTCHours(0,0,0,0)
    const isViewModeOnly = dealState._is_view_mode_only
    const isLocked = dealState.is_locked

    const isCashPurchase = dealState.is_purchase && dealState.is_one_pay

    const headerTitle = {
        true: 'LEASE',
        [dealState.is_purchase]: 'FINANCE',
        [isCashPurchase]: 'CASH'
    }.true

    return (
        <Box>
            {
                isViewModeOnly &&
                <Typography
                    variant="h5"
                    component="div"
                    className={'header-title'}
                >
                    VIEW MODE ONLY
                </Typography>

            }
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                style={{ color: dealColorLight()}}
                className={'DealPage-header'}
            >
                <div>
                    <Typography
                        variant="h5"
                        component="div"
                        className={'header-title'}
                    >
                        {headerTitle} QUOTE
                        {
                            isModal &&
                            <Link to={`/quotes/${dealState.id}`} target="_blank" rel="noopener noreferrer" >
                                <OpenInNewOutlinedIcon />
                            </Link>
                        }
                        {
                            !isTabletOrMobile &&
                            <Typography
                                variant="subtitle1"
                                component="div"
                                className={'header-subtitle press-enter'}
                            >
                                Updated {" "}
                                {
                                    `${updatedAtDate.getMonth() + 1}/${updatedAtDate.getDate()} ${updatedAtDate.toLocaleTimeString(undefined, {timeStyle:'short'})}`
                                }
                            </Typography>
                        }
                        {
                            isExpired &&
                            <Typography
                                variant="subtitle1"
                                component="div"
                                sx={{
                                    fontSize: '12px',
                                    color: '#A23325',
                                    marginLeft: '10px'
                                }}
                            >
                                <WarningAmberRoundedIcon/>
                                This program is expired.
                            </Typography>
                        }
                    </Typography>
                </div>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    {
                        isFeatureFlagEnabled('crm') &&
                        <div
                            className={'lock-icon'}
                        >

                            {
                                isLocked ?
                                    <Tooltip
                                        title={
                                            <Typography color="inherit">
                                                This deal belongs to a client(s) and is locked. It cannot be updated unless you unlock it by clicking the lock icon.
                                            </Typography>
                                        }
                                    >
                                        <LockIcon
                                            onClick={() => setDealStateWithoutUpdate({...dealState, is_locked: false})}
                                        />
                                    </Tooltip>
                                    :
                                    <LockOpenIcon
                                        onClick={() => setDealStateWithoutUpdate({...dealState, is_locked: true})}
                                    />
                            }
                        </div>
                    }

                    {
                        isFeatureFlagEnabled('crm') &&
                        <div
                            className={'header-button crm'}
                            style={{
                                color: dealColorLight(),
                                border: `2px solid ${dealColorLight()}`
                            }}
                            onClick={() => {
                                setIsDealCRMModalOpen({
                                    initialSelectedLeads: dealState.leads,
                                    initialSelectedClients: dealState.clients,
                                    onLeadsSave: (leadIds) => {
                                        setDealStateWithoutQuoteUpdate({leads: leadIds})
                                        setIsDealCRMModalOpen(null)
                                    },
                                    onClientsSave: (clientIds) => {
                                        setDealStateWithoutQuoteUpdate({clients: clientIds})
                                        setIsDealCRMModalOpen(null)
                                    }
                                })
                            }}
                        >
                            <GroupAddIcon/>
                        </div>
                    }

                    <div
                        onClick={handleClick}
                        className={'header-button tools'}
                        style={{ background: dealColorLight() }}
                    >
                        Tools
                        <ArrowDropDownIcon/>
                    </div>
                    <Popover
                        onClick={(e) => e.stopPropagation()}
                        open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            horizontal: 'right',
                            vertical: 'top'
                        }}
                        className={'deal-header-dropdown-menu-container'}
                    >
                        <Box className={'deal-header-dropdown-menu'}>
                            <a
                                onClick={() => setIsCustomerPDFModalOpen({dealState: dealState})}
                                className={classnames('header-button', {
                                    isPurchase: isPurchase
                                })}
                            >

                                <span
                                    style={{ marginRight: '20px'}}
                                >
                                    <PictureAsPdfIcon
                                        className={classnames('', {
                                            isPurchase: isPurchase
                                        })}
                                    />
                                 </span>
                                <span>Customer PDF</span>
                            </a>
                            <a
                                onClick={() => setIsDealerPDFModalOpen({dealState: dealState})}
                                className={classnames('header-button', {
                                    isPurchase: isPurchase
                                })}
                            >
                                <span
                                    style={{ marginRight: '20px'}}
                                >
                                    <PictureAsPdfIcon
                                        className={classnames('', {
                                            isPurchase: isPurchase
                                        })}
                                    />
                                 </span>
                                <span>Dealer PDF</span>
                            </a>

                            <div
                                onClick={deskClonedDeal}
                                className={classnames('header-button', {
                                    isPurchase: isPurchase
                                })}
                            >
                                <span
                                    style={{ marginRight: '20px'}}
                                >
                                    { cloneIcon() }
                                </span>
                                <span>
                                    Desk as { isPurchase ? 'Lease ' : 'Purchase '}
                                </span>
                            </div>
                            {
                                !isCashPurchase &&
                                <div
                                    onClick={deskCashPurcahse}
                                    className={classnames('header-button', {
                                        isPurchase: isPurchase
                                    })}
                                >
                                <span
                                    style={{ marginRight: '20px'}}
                                >
                                    <AttachMoneyIcon/>
                                </span>
                                    <span>
                                    Desk as Cash
                                </span>
                                </div>
                            }

                            {
                                true &&
                                <div
                                    onClick={cloneDealAndOpenModal}
                                    className={classnames('header-button', {
                                        isPurchase: isPurchase
                                    })}
                                >
                                    <span
                                        style={{ marginRight: '20px'}}
                                    >
                                        <ContentCopyIcon/>
                                    </span>
                                        <span>
                                        Clone Quote
                                    </span>
                                </div>
                            }
                        </Box>
                    </Popover>
                </Stack>
            </Stack>
        </Box>
    );
};

export default DealHeader;