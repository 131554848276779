import React, {useState} from 'react';

import {useUserContext} from "../../../contexts/UserContext";
import {CreateClientItem} from "./CreateClientItem";
import {useDebounce} from "@uidotdev/usehooks";
import useDidMountEffect from "../../../hooks/useDidMountEffect";

export const CustomClientColumnHeader = ({ column, columnNum }) => {
    const { profileState, setProfileStateWithUpdate } = useUserContext()
    const [columnName, setColumnName] = useState(column['display_name'])
    const debouncedColumn = useDebounce(columnName, 300)

    useDidMountEffect(function updateLeadColumnHeader() {
        const newClientColumns = structuredClone(profileState.client_columns)
        newClientColumns[columnNum]["display_name"] = columnName

        setProfileStateWithUpdate({...profileState, client_columns: newClientColumns})
    }, [debouncedColumn])

    return (
        <th
            key={columnNum}
            width={'10%'}
            className={'crm_table_header'}
        >
            <input
                className=""
                type="text"
                value={columnName}
                onChange={(e) => setColumnName(e.target.value)}
            />
        </th>
    )

}


export const ClientsTableHeaders = () => {
    const { profileState } = useUserContext()
    let columns = profileState.client_columns

    delete columns['Car']

    const sortedColumns = Object.keys(columns).sort((a, b) => columns[a] - columns[b])

    return (
        <thead>
        <tr className={"clients_table_row"}>
            <th
                width={'5%'}
                key='Name' className={'crm_table_header'}>
                Name
            </th>

            <th
                width={'3%'}
                key='Phone' className={'crm_table_header'}>
                Phone
            </th>
            <th
                width={'5%'}
                key='Email' className={'crm_table_header'}>
                Email
            </th>
            <th key='Vehicle' width={'10%'} className={'crm_table_header'}>
                Vehicle
            </th>
            <th
                width={'2%'}
                key='Profit' className={'crm_table_header'}>
                Profit
            </th>
            <th
                width={'1%'}
                key='Inception Date' className={'crm_table_header'}>
                Inception
            </th>
            <th
                width={'1%'}
                key='Maturity Date' className={'crm_table_header'}>
                Maturity
            </th>
            {
                sortedColumns.map((columnNum) => (
                        <CustomClientColumnHeader
                            key={columnNum}
                            column={columns[columnNum]}
                            columnNum={columnNum}/>
                    )
                )
            }

            <th key='Actions' width={'1%'} className={'crm_table_header'}>
                Actions
            </th>

        </tr>
        <CreateClientItem/>
        </thead>
    )
}