import React from 'react'
import fetcher from "../../../fetcher";
import Stack from "@mui/material/Stack";
import {useMediaQuery} from "react-responsive";
import {mediaQueryForMobile} from "../../HomePage/HomePage";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import {useFetchResource} from "../../../hooks/useFetchResource";

export const MiniLeadItem = ({ leadId, toggleSelected }) => {
    const isTabletOrMobile = useMediaQuery({ query: mediaQueryForMobile })
    const [leadState, setLeadState] = useFetchResource(fetcher.getLead, leadId)

    if (!leadState) return null;

    return (
        <Stack
            direction={isTabletOrMobile ? 'column' : 'row'}
            alignItems="center"
            className={'MiniLeadItem'}
        >
            {
                toggleSelected &&
                <CancelOutlinedIcon
                    onClick={(e) => {
                        e.stopPropagation()
                        toggleSelected()
                    }}
                    className={'action-icon'}
                />
            }
            <div>
                <div>{leadState.name}</div>
                <div>{leadState.email}</div>
            </div>
        </Stack>
    )
}