import React  from "react";
import {useDealContext} from "../../contexts/DealContext";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import useFeeStateHook from "../../hooks/useFeeStateHook";
import Switch from "@mui/material/Switch";
import FeeHelper from "../../utils/FeeHelper";
import CurrencyInput from "react-currency-input-field";
import {useMediaQuery} from "react-responsive";
import {mediaQueryForMobile} from "../HomePage/HomePage";

export const DealPageTaxItem = ({ taxItem, amount }) => {
    const isTabletOrMobile = useMediaQuery({ query: mediaQueryForMobile })
    const {updateDealResponse} = useDealContext()
    const [feeState, setFeeStateWithUpdate] = useFeeStateHook(taxItem)
    const feeHelper = new FeeHelper(feeState)

    const isLoading = updateDealResponse?.loading

    return (
        <TableRow
            key={feeHelper.uniqueId()}
            sx={{
                '&:last-child td, &:last-child th': {border: 0},
                height: '10px',
            }}
        >

            <TableCell
                className={'DealPageFee fee-name'}
                scope="row"
                width={'25%'}
                align="left"
            >
                {feeState.Name}
            </TableCell>
            {
                !isTabletOrMobile &&
                <TableCell
                    className={'DealPageFee fee-name'}
                    scope="row"
                    size={'small'}
                    width={'25%'}
                    align="right"
                >
                    <CurrencyInput
                        className={'payment-row_value disabled'}
                        prefix="$"
                        value={Math.abs(amount || feeState.Amount)}
                        disabled={true}
                    />
                </TableCell>
            }
            <TableCell
                className={'DealPageFee'}
                align="right"
                size={'small'}
                width={'10%'}
            >
                <Switch
                    disabled={isLoading}
                    checked={feeState.FinanceTaxes === 'Yes'}
                    onChange={() => {
                        const financeTaxes = feeState?.FinanceTaxes === 'Yes' ? 'No' : 'Yes'
                        setFeeStateWithUpdate({FinanceTaxes: financeTaxes}, true)
                    }}
                />
            </TableCell>
            <TableCell
                align="right"
                size={'small'}
                className={'DealPageFee'}
                width={'10%'}
            >
                <CurrencyInput
                    className={'payment-row_value disabled'}
                    prefix="$"
                    value={feeState.CapitalizedTax || feeState.UpfrontTax || feeState.Tax}
                    disabled={true}
                />
            </TableCell>
        </TableRow>
    )
}