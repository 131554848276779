export const BASE_URL = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API_URL : 'https://127.0.0.1:8000'

const CARS_URL = 'cars'
const PRICING_URL = 'pricing'
const DEALS_URL = 'deals'
const LEADS_URL = 'leads'
const CLIENTS_URL = 'clients'
const ACCOUNTS_URL = 'accounts'

const CREATE_URL = 'create'
const UPDATE_URL = 'update'
const DELETE_URL = 'delete'

export const URLS = {
    getCSRFCookie: () => `/get_csrf_cookie`,
    logError: () => `/log_error`,

    login: () =>  `/accounts/login`,
    logout: () => `/accounts/logout`,
    changePassword: () => `/accounts/change_password`,
    refreshToken: () => `/accounts/token/refresh`,

    //CAR URLS
    makesForYear: (year) => `/${CARS_URL}/${year}`,
    modelsForMake: (year, make) => `/${CARS_URL}/${year}/${make}`,
    trimsForModel: (year, make, model) => `/${CARS_URL}/${year}/${make}/${model}`,
    getCar: () => `/${CARS_URL}/`,

    // PRICING URLS
    getPricingsForMake: (make) => `/${PRICING_URL}/${make}`,
    createPricing: (make) => `/${PRICING_URL}/${CREATE_URL}`,
    updatePricing: (id) => `/${PRICING_URL}/${id}/${UPDATE_URL}`,
    deletePricing: (id) => `/${PRICING_URL}/${id}/${DELETE_URL}`,

    // DEAL URLS
    getDeal: (id, params) => params ? `/${DEALS_URL}/${id}?${params}` : `/${DEALS_URL}/${id}`,
    getDeals: (params) => params ? `/${DEALS_URL}?${params}` : `/${DEALS_URL}`,
    createDeal: () => `/${DEALS_URL}/${CREATE_URL}`,
    updateDeal: (id) => `/${DEALS_URL}/${id}/${UPDATE_URL}`,
    deleteDeal: (id) => `/${DEALS_URL}/${id}/${DELETE_URL}`,

    // Desking
    getDesking: () => `/${DEALS_URL}/desking`,

    // PROFILE URLS
    // Location
    getProfile: () => `/${ACCOUNTS_URL}/profile`,
    updateProfile: () => `/${ACCOUNTS_URL}/profile/update`,

    getLocations: () => `/${ACCOUNTS_URL}/locations`,
    createLocation: () => `/${ACCOUNTS_URL}/location`,
    updateLocation: (id) => `/${ACCOUNTS_URL}/location/${id}/${UPDATE_URL}`,
    deleteLocation: (id) => `/${ACCOUNTS_URL}/location/${id}`,

    // Leads
    getLead: (id, params) => params ? `/${LEADS_URL}/${id}?${params}` : `/${LEADS_URL}/${id}`,
    getLeads: (params) => params ? `/${LEADS_URL}?${params}` : `/${LEADS_URL}`,
    createLead: () => `/${LEADS_URL}/${CREATE_URL}`,
    updateLead: (id) => `/${LEADS_URL}/${id}/${UPDATE_URL}`,
    deleteLead: (id) => `/${LEADS_URL}/${id}/${DELETE_URL}`,
    uploadLeadsCSV: () => `/${LEADS_URL}/upload_csv`,
    bulkUploadLeads: () => `/${LEADS_URL}/bulk_upload`,

    // Clients
    getClient: (id, params) => params ? `/${CLIENTS_URL}/${id}?${params}` : `/${CLIENTS_URL}/${id}`,
    getClients: (params) => params ? `/${CLIENTS_URL}?${params}` : `/${CLIENTS_URL}`,
    createClient: () => `/${CLIENTS_URL}/${CREATE_URL}`,
    updateClient: (id) => `/${CLIENTS_URL}/${id}/${UPDATE_URL}`,
    deleteClient: (id) => `/${CLIENTS_URL}/${id}/${DELETE_URL}`,
    bulkUploadClients: () => `/${CLIENTS_URL}/bulk_upload`,
}