import React, {useEffect, useState} from 'react'

export const useFetchResource = (fetchFunc, id) => {
    const [state, setState] = useState(null)

    useEffect(function fetchResource(){
        fetchFunc(id).then((data) => {
            setState(data?.data)
        })
    }, [id])

    return [state, setState]
}