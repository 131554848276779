import React from 'react'
import Typography from "@mui/material/Typography";
import {useMediaQuery} from "react-responsive";
import {mediaQueryForMobile} from "../HomePage/HomePage";
import {DeskingTableRowCell} from "./DeskingTableRowCell";
import QuoteHelper from "../../utils/QuoteHelper";

export const DeskingTableRow = ( { term, quotes, lenders, dealId, dealState, onSave, sortBy } ) => {
    const isTabletOrMobile = useMediaQuery({ query: mediaQueryForMobile })

    const isSortByLender = sortBy === 'Lender'
    const isSortByBestPayment = sortBy === 'BestPayment'

    return (
        <tr>
            <td className={'term'}>
                <Typography
                    fontWeight={400}
                    fontSize={20}
                    variant="div"
                    gutterBottom>
                    {term}
                </Typography>
            </td>
            {
                isSortByBestPayment &&
                quotes.map((quote, index) => {
                    // const quote = quotes[lender]
                    const quoteHelper = new QuoteHelper(dealState, quote)

                    return <DeskingTableRowCell
                        isPurchase={dealState.is_purchase}
                        dealId={dealId}
                        quote={quote}
                        key={index}
                        onSave={onSave}
                        lender={quoteHelper.lender()}
                        term={term}
                    />
                })
            }
            {
                isSortByLender &&
                lenders.map((lender, index) => {
                    const quote = quotes[lender]

                    return <DeskingTableRowCell
                        isPurchase={dealState.is_purchase}
                        dealId={dealId}
                        quote={quote}
                        key={index}
                        onSave={onSave}
                        lender={lender}
                        term={term}
                    />
                })
            }
        </tr>
    )
}