import React, { useRef } from 'react';

import {LeadsTableHeaders} from "./LeadsTableHeaders";
import {LeadItem} from "./LeadItem";
import {useCRMContext} from "../../../contexts/CRMContext";
import CustomInfiniteScroll from "../../../components/CustomInfiniteScroll";

export const LeadsTable = () => {
    const { listLeadsState, shouldFetchNextLeads, fetchNextLeads } = useCRMContext()

    const leads = listLeadsState.results

    return (
        <div className={'parent-scroll-ref'} id={"LeadsTableParentScrollRef"}>
            <table className={'LeadsTable'}>
                <LeadsTableHeaders/>
                <CustomInfiniteScroll
                    initialLoad={Boolean(!leads.length)}
                    useWindow={false}
                    pageStart={listLeadsState.current_page}
                    loadMore={(page) => {
                        return Boolean(shouldFetchNextLeads() && fetchNextLeads())
                    }}
                    hasMore={listLeadsState.has_more}
                    element={'tbody'}
                    className={'LeadsTable-container'}
                    getScrollParent={() => document.getElementById("LeadsTableParentScrollRef")}
                >
                    {leads.map((lead, idx) => (
                        <LeadItem key={lead.id} lead={lead}/>
                    ))}
                </CustomInfiniteScroll>
        </table>
        </div>
    )
}