import React from 'react'
import Box from "@mui/material/Box";

import {useMediaQuery} from "react-responsive";
import {mediaQueryForMobile} from "../../HomePage/HomePage";
import {ClientsTable} from "./ClientsTable";

import {ClientsPageHeader} from "./ClientsPageHeader";

export const ClientsPage = () => {
    const isTabletOrMobile = useMediaQuery({ query: mediaQueryForMobile })

    return (
        <Box
            id={"ClientsPage"}
            style={{
                display: isTabletOrMobile ? '' : 'flex',
                height: '100vh',
                overflow: isTabletOrMobile ? 'auto' : 'hidden',
                flexDirection: 'column',
            }}
        >
            <Box
                style={{
                    display: isTabletOrMobile ? 'flex' : '',
                    // padding: isTabletOrMobile ? '30px' : '60px 30px',
                    maxWidth: isTabletOrMobile ? '100%' : '',
                    flexDirection: isTabletOrMobile ? 'column' : ''
                }}
            >
                <ClientsPageHeader/>
            </Box>
            <Box
                style={{
                    display: isTabletOrMobile ? 'flex' : '',
                    // padding: isTabletOrMobile ? '30px' : '60px 30px',
                    maxWidth: isTabletOrMobile ? '100%' : '',
                    flexDirection: isTabletOrMobile ? 'column' : '',
                    height: "calc(100% - 77px)",
                }}

            >
                <ClientsTable/>
            </Box>
        </Box>
    )
}