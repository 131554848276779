import React, {useState} from "react";
import Typography from "@mui/material/Typography";


import classnames from "classnames";
import {buildTrimAndStyleDescriptionName} from "../../../utils/utils";
import QuoteHelper from "../../../utils/QuoteHelper";
import {useCRMListDealsContext} from "../../../contexts/CRMListDealsContext";
import Checkbox from "@mui/material/Checkbox";
import {useModalContext} from "../../../contexts/ModalContext";

export const CRMListDealCard = ( { deal, defaultChecked } ) => {
    const [dealState, setDealState] = useState(deal)
    const { onSelect } = useCRMListDealsContext()
    const { setIsDealModalOpen } = useModalContext()

    const car_detail = dealState.car_detail

    const isLoading = false // updateDealResponse?.loading && dealId() === dealState.id
    const isPurchase = dealState.is_purchase
    const helper = new QuoteHelper(dealState, dealState.selected_quote)

    return (
        <div
            onClick={
                () => setIsDealModalOpen({
                    id: dealState.id,
                    onSave: (newDealState) => setDealState(newDealState)
                })
            }
            className={classnames('ListDealCard', {
                isPurchase: isPurchase
            })}
        >
            <Checkbox
                className={'action-icon'}
                onClick={(e) => {
                    e.stopPropagation()
                    onSelect && onSelect(dealState.id, dealState)
                }}
                defaultChecked={defaultChecked}
            />

            <div
                className={'left'}
            >
                <div
                    className={'car-detail'}
                >
                    <Typography
                        sx={{
                            fontSize: '20px',
                            fontWeight: 'bold'
                        }}
                    >
                        {car_detail.year} {car_detail.make} {car_detail.model}
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: '14px',
                        }}
                    >
                        {buildTrimAndStyleDescriptionName(car_detail.trim, car_detail.style_description)}
                    </Typography>
                </div>


                <div
                    className={'ListDealCard-lender'}
                >
                    <Typography
                        sx={{
                            fontSize: '14px',
                        }}
                    >
                        {helper.lender()}
                    </Typography>

                </div>

                <label>Description</label>
                <div className={'ListDealCard-description'}>
                    {dealState.description}
                </div>
            </div>
            <div
                className={classnames('right', {
                    // isPurchase: isPurchase
                })}
            >
                <div
                    className={'pay-detail'}
                >
                    <Typography
                        className={classnames('pay-detail_value', {
                            loading: isLoading
                        })}
                        sx={{
                            fontSize: '14px',
                            fontWeight: 'bold',
                            borderBottom: '1px solid white'
                        }}
                    >
                        { isPurchase ? 'PURCHASE' : 'LEASE'}
                    </Typography>
                    <Typography
                        className={classnames('pay-detail_value', {
                            loading: isLoading
                        })}
                        sx={{
                            fontSize: '14px',
                            fontWeight: 'bold'
                        }}
                    >
                        ${isPurchase ? helper.cashDueOnDelivery() : helper.amountDueOnDelivery()}
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: '10px',
                        }}
                    >
                        Due at Signing
                    </Typography>

                    <Typography
                        className={classnames('pay-detail_value', {
                            loading: isLoading
                        })}
                        sx={{
                            fontSize: '14px',
                            fontWeight: 'bold'
                        }}
                    >
                        ${isPurchase ? helper.monthlyPayment() : helper.advancePayment()}
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: '10px',
                        }}
                    >
                        Per Month
                    </Typography>
                    <Typography
                        className={classnames('pay-detail_value', {
                            loading: isLoading
                        })}
                        sx={{
                            fontSize: '14px',
                            fontWeight: 'bold'
                        }}
                    >
                        {dealState.term}
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: '10px',
                        }}
                    >
                        Months
                    </Typography>
                    <Typography
                        className={classnames('pay-detail_value', {
                            loading: isLoading
                        })}
                        sx={{
                            fontSize: '14px',
                            fontWeight: 'bold'
                        }}
                    >
                        {dealState.mileage}
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: '10px',
                        }}
                    >
                        Miles
                    </Typography>
                    <Typography
                        className={classnames('pay-detail_value', {
                            loading: isLoading
                        })}
                        sx={{
                            fontSize: '14px',
                            fontWeight: 'bold',
                        }}
                    >
                        ${dealState.profit}
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: '10px',
                        }}
                    >
                        Profit
                    </Typography>
                </div>
            </div>
        </div>
    )
}