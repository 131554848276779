import {useMediaQuery} from "react-responsive";
import {mediaQueryForMobile} from "../HomePage/HomePage";
import {useDeskingContext} from "../../contexts/DeskingContext";
import {useModalContext} from "../../contexts/ModalContext";
import React, {useState} from "react";
import QuoteHelper from "../../utils/QuoteHelper";
import {SkeletonLoader} from "../../components/SkeletonLoader";
import classnames from "classnames";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import {useUserContext} from "../../contexts/UserContext";

export const DeskingTableRowCell = ({dealId, quote, isPurchase, term, lender}) => {
    const isTabletOrMobile = useMediaQuery({query: mediaQueryForMobile})
    const { profileState } = useUserContext()
    const {
        createDeal,
        createDealResponse,
        updateDeal,
        updateDealResponse,
        deskingState,
        onSave,
        pricing_id,
        selectedTerm,
        selectedLender,
    } = useDeskingContext()


    const isSelected = Boolean(
        String(selectedTerm) === String(term) &&
        String(lender) === String(selectedLender)
    )


    const {setIsDeskingModalOpen} = useModalContext()
    const [selected, setSelected] = useState(isSelected)

    const getLeadId = () => {
        const urlParams = new URLSearchParams(window.location.search);

        if (urlParams.get('lead_id')) {
            return urlParams.get('lead_id')
        }
    }

    const saveQuote = (quote) => {
        setSelected(true)
        const helper = new QuoteHelper(null, quote)

        const updateData = {
            selected_quote: quote,
            term: helper.term(),
            mileage: helper.annualMileage(),
            _no_update: true,
            pricing_id: pricing_id,
        }


        if (dealId) {
            return updateDeal(dealId, updateData).then((data) => {
                const updateData = data?.data
                onSave && onSave(updateData)
                setSelected(false)
                setIsDeskingModalOpen(null)
            }).catch(() => {
                setSelected(false)
            })
        } else {
            if (getLeadId()) {
                updateData['leads'] = [getLeadId()]
            }
            return createDeal({...deskingState, ...updateData, _no_update: true}).then((data) => {
                const createData = data?.data

                onSave && onSave(createData)
                setSelected(false)
                setIsDeskingModalOpen(null)

            }).catch(() => {
                setSelected(false)
            })
        }


    }

    if (!quote) {
        return <td className={'disabled'}></td>
    }

    const helper = new QuoteHelper(null, quote)

    const isLoading = (createDealResponse?.loading || updateDealResponse?.loading)
    const isLoadingAndSelected = isLoading && selected
    const isCashPurchase = deskingState.is_one_pay && isPurchase
    const isSortByLender = profileState?.desking_sort_by === 'Lender'

    return (
        <SkeletonLoader loading={isLoadingAndSelected}>
            <td
                onClick={() => {
                    if (!isLoading) {
                        saveQuote(quote)
                    }
                }}
                className={'quote'}
            >
                <Stack
                    direction="column"
                    justifyContent="space-between"
                    alignItems="start"
                    className={classnames('info', {
                        selected: selected,
                        isPurchase: isPurchase,
                    })}
                >
                    {
                        !isCashPurchase &&
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            className={'info-payment'}
                        >
                            <Typography fontWeight={700} variant="div">
                                ${isPurchase ? helper.monthlyPayment() : helper.advancePayment()}
                            </Typography>
                        </Stack>
                    }
                    {
                        isCashPurchase &&
                        <div className={'info-payment'}>
                            <Typography fontWeight={700} variant="div">
                                ${helper.payment()}
                            </Typography>
                        </div>
                    }
                    {
                        !isSortByLender &&
                        <Typography
                            fontWeight={700}
                            variant="div"
                            fontSize={10}
                            style={{
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                width: '100px',
                                textOverflow: 'ellipsis',
                            }}
                        >
                            {helper.lender()}
                        </Typography>
                    }
                </Stack>

            </td>
        </SkeletonLoader>
    )
}