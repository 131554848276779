import React, {useState, useRef} from 'react'
import logo from "../../assets/pngs/white-logo-only.png";
import blackFull from "../../assets/svgs/black-full.svg";

import CalculateIcon from '@mui/icons-material/Calculate';
import RequestQuoteOutlinedIcon from "@mui/icons-material/RequestQuoteOutlined";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import EditLocationIcon from "@mui/icons-material/EditLocation";
import PersonIcon from "@mui/icons-material/Person";
import emailjs from '@emailjs/browser';

import './LandingPage.scss'
import {toast} from "react-toastify";


export const LandingPage = () => {
    const [isContactFormVisible, setIsContactFormVisible] = useState(false)
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm('service_rwd4ja4', 'template_x0owgom', form.current, {
                publicKey: 'Ve1AbbNKoHOpGbiwp',
            })
            .then(
                () => {
                    toast.success("Success. Someone will reach out to you shortly.")
                },
                (error) => {
                    toast.error("Something went wrong, please try again.")
                },
            );
    };

    return (
        <div id={"LandingPage"}>
            <section id="header" style={{ position: 'relative'}}>
                <ul className="sign-in">
                    <li><a href="/login" className="button scrolly">Sign in</a></li>
                </ul>
                <div className="inner">
                    <img src={logo} alt="Logo" style={{ width: '100px'}} />
                    <h1>Welcome to <strong>TrueDesking.com,</strong><br />
                        desking for brokers, by brokers.</h1>
                    <p>Auto brokers have been using outdated technology for far too long. <br />
                        TrueDesking.com is the modern web app that we've come to expect.</p>
                    <ul className="actions special">
                        <li><a href="#one" className="button scrolly">Learn more</a></li>
                    </ul>
                </div>
            </section>

            <section id="one" className="main style1">
                <div className="container">
                    <div className="row gtr-150">
                        <div className="col-6 col-12-medium">
                            <header className="major">
                                <h2>Calculate leases in seconds.</h2>
                            </header>
                            <p>Our lease calculator is as simple as it looks. Just select a car and set pricing, and
                                TrueDesking pulls quotes for all lenders with multiple terms for any location.</p>
                        </div>
                        <div className="col-6 col-12-medium imp-medium">
                            <span className="image fit"><img src={"https://res.cloudinary.com/hgjsxpz9b/image/upload/v1738862440/CalculateFlowLarge_wslyle.gif"} alt=""/></span>
                        </div>
                    </div>
                </div>
            </section>

            <section id="two" className="main style2">
                <div className="container">
                    <div className="row gtr-150">
                        <div className="col-6 col-12-medium">
                            <ul className="major-icons">
                                <li><span className="icon solid style1 major"><CalculateIcon/> </span></li>
                                <li><span className="icon solid style2 major"><RequestQuoteOutlinedIcon/></span></li>
                                <li><span className="icon solid style3 major"><AccountBalanceIcon/></span></li>
                                <li><span className="icon solid style4 major"><LocalOfferIcon/></span></li>
                                <li><span className="icon solid style5 major"><EditLocationIcon/></span></li>
                                <li><span className="icon solid style6 major"><PersonIcon/></span></li>
                            </ul>
                        </div>
                        <div className="col-6 col-12-medium">
                            <header className="major">
                                <h2>Simplicity is the name of the game.</h2>
                            </header>
                            <p>Outdated desking tools are bloated with too many inputs. Most desking tools are an eye sore to look at. TrueDesking simplifies
                            its interface to only display the most relevant information, making it a pleasure to use.</p>
                            <p>Navigating TrueDesking.com is smooth and intuitive. Pages and features are one click away at all times, without opening new pages
                            and endless loading.</p>
                            <p>TrueDesking stores all relevant data so you don't have to re-enter information everytime. Quotes,
                                pricing information, and locations for tax purposes are all saved and at your fingertips.</p>
                            <p>Pricing information is stored across your companys account, so you can avoid duplicate information. Once MSRPs/Invoice/Profit
                            data is entered on the pricing page it is shared across your organization seamlessly.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section id="three" className="main style1 special">
                <div className="container">
                    <header className="major">
                        <h2>Feature rich @ Unbeatable price</h2>
                    </header>
                    <p>New applications are easier to develop, and we pass those savings on to you.</p>
                    <div className="row gtr-150">
                        <div className="col-4 col-12-medium">
                            <span className="image fit"><img src={"https://res.cloudinary.com/hgjsxpz9b/image/upload/v1738862383/Screenshot_2025-02-06_at_11.52.09_AM_etaghc.png"} alt="" /></span>
                            <h3>Pricing Index</h3>
                            <p>Easily store MSRP, Invoice, Profit and any other information you get from dealships. Now you're one click away from the latest quotes.</p>
                        </div>
                        <div className="col-4 col-12-medium">
                            <span className="image fit"><img src={"https://res.cloudinary.com/hgjsxpz9b/image/upload/v1738867485/PurchaseQuotesModalRecording_bfcqoq.gif"} alt="" /></span>
                            <h3>Compare</h3>
                            <p>After calculating a quote you can easily recalculate the quotes and compare lenders, for both financing and purchases.</p>
                        </div>
                        <div className="col-4 col-12-medium">
                            <span className="image fit"><img src={"https://res.cloudinary.com/hgjsxpz9b/image/upload/v1738862383/Screenshot_2025-02-06_at_12.08.58_PM_lgelah.png"} alt="" /></span>
                            <h3>Rebates, Fees and Taxes</h3>
                            <p>All rebates, fees and taxes can be easily maniplated. Brokers can add or remove custom rebates, edit fee amounts, and
                            cap/set upfront any of them.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section id="four" className="main style2 special">
                <div className="container">
                    <header className="major">
                        <h2>Want to see for yourself?</h2>
                    </header>
                    <p>Contact our team to get started.</p>
                    <ul className="actions special">
                        <li><a onClick={() => setIsContactFormVisible(true)} className="button wide primary">Contact us</a></li>
                    </ul>
                    {
                        isContactFormVisible &&
                        <div className="container">
                            <form ref={form} onSubmit={sendEmail}>
                                <label>Name</label>
                                <input type="text" name="from_name" />
                                <label>Email</label>
                                <input type="email" name="user_email" />
                                <label>Message</label>
                                <textarea name="message" />
                                <input type="submit" value="Send" className={'send-email-button'}/>
                            </form>
                        </div>
                    }
                </div>
            </section>


            <section id="footer">
                <ul className="icons">
                    <li><a href="#" className="icon brands alt fa-twitter"><span className="label">Twitter</span></a></li>
                    <li><a href="#" className="icon brands alt fa-facebook-f"><span className="label">Facebook</span></a></li>
                    <li><a href="#" className="icon brands alt fa-instagram"><span className="label">Instagram</span></a></li>
                    <li><a href="#" className="icon brands alt fa-github"><span className="label">GitHub</span></a></li>
                    <li><a href="#" className="icon solid alt fa-envelope"><span className="label">Email</span></a></li>
                </ul>
                <ul className="copyright">
                    <li>support@truedesking.com</li>
                    <li><img src={blackFull} alt="Logo" style={{ width: '150px'}} /></li>
                    <li>+1 (323) 600-5926</li>
                </ul>
            </section>
        </div>
    )
}