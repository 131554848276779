import React, {useState} from "react";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import CustomCurrencyInput from "../../components/CustomCurrencyInput";
import classnames from "classnames";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import {LEASE_DEAL_LIGHT, PURCHASE_DEAL_LIGHT} from "../../contexts/DealContext";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import _ from "lodash";
import {useCalcContext} from "../../contexts/CalcContext";

export const CalcPagePricing = ({ createNewLease, createNewPurchase }) => {
    const [isCreatingLease, setIsCreatingLease] = useState(false)
    const [isCreatingPurchase, setIsCreatingPurchase] = useState(false)

    const {
        calcState,
        setCalcState,
        carState,
    } = useCalcContext()

    const isLoading = (isCreatingPurchase || isCreatingLease)

    const needsVin = carState.vin_required
    const hasVin = calcState.vin
    const missingVin = Boolean(needsVin && !hasVin)
    const isInputDisabled = !carState.year || !carState.make || !carState.model || !carState.trim || !carState.style_description || missingVin
    const isDisabled = !calcState.msrp || !calcState.cost

    const sellingPrice =  _.round(Number(calcState.cost) + Number(calcState.profit), 2)
    return (
        <Stack
            direction="column"
            justifyContent="space-between"
            style={{
                height: '100%'
            }}
        >
            <div>
                <Typography
                    gutterBottom
                    variant="h6"
                    component="div"
                    className={'header'}
                    sx={{
                        fontSize: '24px',
                        fontWeight: 'bold',
                        marginBottom: '10px',
                    }}
                >
                    Vehicle Pricing
                </Typography>
                <Divider className={'divider'} sx={{ marginBottom: '10px'}}/>

                <div
                    className={'payment-row'}
                >
                        <span
                            className={'payment-row_name'}
                        >
                            MSRP:
                        </span>
                    <CustomCurrencyInput
                        className={classnames('payment-row_value', {
                            loading: isLoading
                        })}
                        placeholder={'0'}
                        value={calcState.msrp}
                        onChange={(value) => setCalcState({...calcState, msrp: value})}
                        allowNull={true}
                        disabled={isInputDisabled}
                    />
                </div>
                <div
                    className={'payment-row'}
                >
                        <span
                            className={'payment-row_name'}
                        >
                            Cost:
                        </span>
                    <CustomCurrencyInput
                        className={classnames('payment-row_value', {
                            loading: isLoading
                        })}
                        placeholder={'0'}
                        value={calcState.cost}
                        onChange={(value) => setCalcState({...calcState, cost: value})}
                        allowNull={true}
                        disabled={isInputDisabled}
                    />
                </div>
                <div
                    className={'payment-row'}
                >
                        <span
                            className={'payment-row_name'}
                        >
                            Profit:
                        </span>
                    <CustomCurrencyInput
                        className={classnames('payment-row_value', {
                            loading: isLoading
                        })}
                        prefix="$"
                        value={calcState.profit}
                        onChange={(value) => setCalcState({...calcState, profit: value})}
                        disabled={isInputDisabled}
                    />
                </div>
                <Divider className={'divider'} sx={{ marginBottom: '10px'}}/>
                <div
                    className={'payment-row'}
                >
                        <span
                            className={'payment-row_name'}
                        >
                            Selling Price:
                        </span>
                    <CustomCurrencyInput
                        className={classnames('payment-row_value disabled', {
                            loading: isLoading
                        })}
                        value={sellingPrice}
                        disabled={true}
                    />
                </div>
                <Divider className={'divider'} />
            </div>
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="end"
            >
                <Tooltip title="Press Enter to Calculate" arrow placement={'bottom'}>
                        <span>
                            <Button
                                className={classnames('dealPage-button', {
                                    isDisabled: isDisabled
                                })}
                                style={{ background: LEASE_DEAL_LIGHT }}
                                onClick={createNewLease}
                                disabled={isDisabled}
                            >
                                {
                                    isCreatingLease ? 'Calculating...' : 'Lease'
                                }
                                {
                                    isCreatingLease ? null: <KeyboardReturnIcon/>
                                }
                            </Button>
                        </span>
                </Tooltip>
                <span>
                        <Button
                            className={classnames('dealPage-button', {
                                isDisabled: isDisabled
                            })}
                            style={{
                                background: '#FFFFFF',
                                color: PURCHASE_DEAL_LIGHT,
                                border: `1px solid`
                            }}
                            onClick={() => createNewPurchase(false)}
                            disabled={isDisabled}
                        >
                            {
                                isCreatingPurchase ? 'Calculating...' : 'Finance'
                            }
                        </Button>
                    </span>
                <span>
                        <Button
                            className={classnames('dealPage-button', {
                                isDisabled: isDisabled
                            })}
                            style={{
                                background: '#FFFFFF',
                                color: PURCHASE_DEAL_LIGHT,
                                border: `1px solid`
                            }}
                            onClick={() => createNewPurchase(true)}
                            disabled={isDisabled}
                        >
                            {
                                isCreatingPurchase ? 'Calculating...' : 'Cash'
                            }
                        </Button>
                    </span>
            </Stack>
        </Stack>
    )
}