import React from 'react';

import {useUserContext} from "../../../contexts/UserContext";
import {CustomLeadColumnHeader} from "../../CRM/LeadsPage/LeadsTableHeaders";

export const DealCRMLeadsTableHeaders = () => {
    const { profileState } = useUserContext()
    const columns = profileState.lead_columns

    const sortedColumns = Object.keys(columns).sort((a, b) => columns[a] - columns[b])

    return (
        <thead>
        <tr className={"leads_table_row"}>
            <th
                width={'1%'}
                key='Select All'
                className={'crm_table_header select'}
            >
            </th>
            <th
                width={'5%'}
                key='Name' className={'crm_table_header'}>
                Name
            </th>

            <th
                width={'3%'}
                key='Phone' className={'crm_table_header'}>
                Phone
            </th>
            <th
                width={'5%'}
                key='Email' className={'crm_table_header'}>
                Email
            </th>
            {
                sortedColumns.map((columnNum) => (
                        <CustomLeadColumnHeader
                            key={columnNum}
                            column={columns[columnNum]}
                            columnNum={columnNum}/>
                    )
                )
            }

            <th
                width={'5%'}
                key='My Quotes' className={'crm_table_header'}>
                My Quotes
            </th>

            <th
                width={'2%'}
                key='Actions'
                className={'crm_table_header'}>
                Actions
            </th>

        </tr>
        {/*<CreateLeadItem/>*/}
        </thead>
    )
}