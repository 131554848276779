import CustomCurrencyInput from "../../components/CustomCurrencyInput";
import classnames from "classnames";
import Box from "@mui/material/Box";
import React from "react";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import {useDealContext} from "../../contexts/DealContext";

export const DealPageSuperUserSection = () => {
    const {
        dealState,
        setDealStateWithUpdate,
        updateDealResponse,
    } = useDealContext()

    const isLoading = updateDealResponse?.loading

    return (
        <Box
            className={classnames("dealPagePayment-column leftColumn")}
            style={{
                height: '100%',
                marginTop: "30px",
                minHeight: "308px",
                color: '#A23325'
            }}
        >
            <Typography
                gutterBottom
                variant="h6"
                component="div"
                sx={{
                  color: '#A23325'
                }}
            >
                SUPERUSER OVERRIDES
            </Typography>

            <Divider className={'divider'}/>

            <div className={'payment-row'}>
                <span
                    className={'payment-row_name'}
                >
                    MONEY FACTOR OVERRIDE:
                </span>
                <CustomCurrencyInput
                    className={classnames('payment-row_value', {
                        loading: isLoading
                    })}
                    prefix=""
                    value={dealState.superuser_money_factor_override}
                    allowBlank={true}
                    thousandSeparator={false}
                    InputProps={{}}
                    onChange={(value) =>  setDealStateWithUpdate({superuser_money_factor_override: value})}
                />
            </div>
        </Box>
    )
}