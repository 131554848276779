import Box from "@mui/material/Box";
import React from "react";
import {useDealContext} from "../../contexts/DealContext";
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';

import Stack from "@mui/material/Stack";
import {useModalContext} from "../../contexts/ModalContext";
import Typography from "@mui/material/Typography";

export const DealPageDrawerHeader = () => {
    const { setIsDealCRMModalOpen } = useModalContext()
    const { dealState, dealColorLight, setDealStateWithoutQuoteUpdate } = useDealContext()

    return (
        <Box
            style={{
                width: '100%',
                padding: '5px 0px 5px 10px'
            }}
            className={'DealPage-header'}
        >
            {/*<Stack*/}
            {/*    direction="row"*/}
            {/*    alignItems="center"*/}
            {/*    justifyContent="space-between"*/}
            {/*    style={{*/}
            {/*        height: '100%'*/}
            {/*    }}*/}
            {/*>*/}
            {/*    <Typography*/}
            {/*        variant="subtitle1"*/}
            {/*        component="div"*/}
            {/*        className={'header-subtitle press-enter'}*/}
            {/*        >*/}
            {/*        {dealState?.leads?.length} Leads, {dealState?.clients?.length} Clients*/}
            {/*    </Typography>*/}
            {/*    <div*/}
            {/*        onClick={() => {*/}
            {/*            setIsDealCRMModalOpen({*/}
            {/*                initialSelectedLeads: dealState.leads,*/}
            {/*                initialSelectedClients: dealState.clients,*/}
            {/*                onLeadsSave: (leadIds) => {*/}
            {/*                    setDealStateWithoutQuoteUpdate({leads: leadIds})*/}
            {/*                    setIsDealCRMModalOpen(null)*/}
            {/*                },*/}
            {/*                onClientsSave: (clientIds) => {*/}
            {/*                    setDealStateWithoutQuoteUpdate({clients: clientIds})*/}
            {/*                    setIsDealCRMModalOpen(null)*/}
            {/*                }*/}
            {/*            })*/}
            {/*        }}*/}
            {/*        className={'header-button'}*/}
            {/*        style={{*/}
            {/*            background: dealColorLight(),*/}
            {/*            marginBottom: '0px',*/}
            {/*        }}*/}
            {/*    >*/}
            {/*        CRM*/}
            {/*        <PeopleOutlinedIcon/>*/}
            {/*    </div>*/}
            {/*</Stack>*/}
        </Box>
    )
}