import React from 'react'
import Typography from "@mui/material/Typography";
import SearchIcon from "@mui/icons-material/Search";
import CircularProgress from '@mui/material/CircularProgress';

import {useMediaQuery} from "react-responsive";
import {mediaQueryForMobile} from "../../HomePage/HomePage";


import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {useLocation} from "react-router";
import Stack from "@mui/material/Stack";

import {useDebounce} from "@uidotdev/usehooks";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import {useDealCRMContext} from "../../../contexts/DealCRMPageContext";
import Button from "@mui/material/Button";
import {LEASE_DEAL_LIGHT} from "../../../contexts/DealContext";

export const DealCRMClientsPageHeader = () => {
    const isTabletOrMobile = useMediaQuery({ query: mediaQueryForMobile })
    const {
        fetchListClients,
        clientsSearchValue,
        setClientsSearchValue,
        currentTab,
        setCurrentTab,
        onClientsSave,
        selectedClients,
    } = useDealCRMContext()
    const debouncedSearch = useDebounce(clientsSearchValue, 300)

    const location = useLocation();

    useDidMountEffect(function fetchListClientsWhenSearchParamsChange(){
        if (clientsSearchValue.length) {
            const params = new URLSearchParams({
                s: clientsSearchValue,
            }).toString()
            fetchListClients(params)
        } else {
            fetchListClients()
        }
    }, [debouncedSearch])

    const isLeadsPath = location.pathname.split('/')[1] === 'leads'

    return (
        <Stack
            id={"CRMHeader"}
            direction={isTabletOrMobile ? 'column' : 'row'}
            alignItems="center"
            justifyContent={'space-between'}
        >
            <Stack
                direction={'row'}
                alignItems="center"
            >
                <Typography
                    sx={{
                        fontSize: '24px',
                        color: '#666'
                    }}
                    component="div"
                >
                    Select Clients
                </Typography>
                <Typography
                    gutterBottom
                    variant="h6"
                    component="div"
                    className={'header'}
                    sx={{
                        fontSize: '24px',
                        fontWeight: '400',
                        marginBottom: '10px',
                        color: "#666666"
                    }}
                >
                    <Button
                        className={'calc-reset-button'}
                        style={{
                            background: LEASE_DEAL_LIGHT,
                            color: '#FFFFFF',
                            marginLeft: '20px',
                        }}
                        onClick={() => {
                            onClientsSave(selectedClients)
                        }}
                    >
                        Save
                    </Button>
                </Typography>
            </Stack>

            <Stack
                // style={{ width: '100%' }}
                direction={isTabletOrMobile ? 'column' : 'row'}
                alignItems="center"
            >
                <Tabs
                    value={currentTab}
                    onChange={(value, newValue) => {
                        setCurrentTab(newValue)
                    }}
                >
                    <Tab label="Leads" value={'/leads'} />
                    <Tab label="Clients" value={'/clients'}/>
                </Tabs>

                <div className={'CRM_header-search-container'}>
                    <input
                        className=""
                        type="text"
                        value={clientsSearchValue}
                        placeholder={`Search ${isLeadsPath ?  'Leads' : 'Clients'}`}
                        onChange={(e) => setClientsSearchValue(e.target.value)}
                    />
                    {
                        false ?
                            <CircularProgress size={20} sx={{ color: "#666666"}}/> :
                            <SearchIcon/>
                    }
                </div>
            </Stack>

        </Stack>
    )
}