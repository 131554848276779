import React, {createContext, useContext, useState} from 'react';

export const ModalContext = createContext(undefined)

export const ModalContextProvider = ( { children } ) => {
    const [isDeskingModalOpen, setIsDeskingModalOpen] = useState(null)
    const [isDealModalOpen, setIsDealModalOpen] = useState(null)
    const [isLocationModalOpen, setIsLocationModalOpen] = useState(null)
    const [isCustomerPDFModalOpen, setIsCustomerPDFModalOpen] = useState(null)
    const [isDealerPDFModalOpen, setIsDealerPDFModalOpen] = useState(null)
    const [isUploadCSVModalOpen, setIsUploadCSVModalOpen] = useState(null)
    const [isCRMListDealsModalOpen, setIsCRMListDealsModalOpen] = useState(null)
    const [isDealCRMModalOpen, setIsDealCRMModalOpen] = useState(null)
    const [isConvertToClientModalOpen, setIsConvertToClientModalOpen] = useState(null)

    const value = {
        isDeskingModalOpen,
        setIsDeskingModalOpen,

        isDealModalOpen,
        setIsDealModalOpen,

        isLocationModalOpen,
        setIsLocationModalOpen,

        isCustomerPDFModalOpen,
        setIsCustomerPDFModalOpen,

        isDealerPDFModalOpen,
        setIsDealerPDFModalOpen,

        isUploadCSVModalOpen,
        setIsUploadCSVModalOpen,

        isCRMListDealsModalOpen,
        setIsCRMListDealsModalOpen,

        isDealCRMModalOpen,
        setIsDealCRMModalOpen,

        isConvertToClientModalOpen,
        setIsConvertToClientModalOpen,
    }

    return (
        <ModalContext.Provider value={value}>
            { children }
        </ModalContext.Provider>
    )
}

export const useModalContext = () => {
    const context = useContext(ModalContext)

    if (context === undefined) {
        throw new Error('useModalContext must be used within ModalContext')
    }
    return context
}