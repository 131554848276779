import Box from "@mui/material/Box";
import React from "react";
import classnames from "classnames";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import CustomCurrencyInput from "../../components/CustomCurrencyInput";
import {useDealContext} from "../../contexts/DealContext";
import {useUserContext} from "../../contexts/UserContext";
import {DealPageAdditionalInfo} from "./DealPageAdditionalInfo";
import {DealPageSuperUserSection} from "./DealPageSuperUserSection";
import Stack from "@mui/material/Stack";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {DealPageDrawerHeader} from "./DealPageDrawerHeader";

export const DealPageDrawer = () => {
    const { isSuperUser } = useUserContext()


    return (
        <Box
            style={{
                marginLeft: '20px',
                minWidth: '365px',
            }}
        >

            <Stack
                direction="column"
                alignItems="center"
            >
                <DealPageDrawerHeader/>

                <DealPageAdditionalInfo/>

                {
                    isSuperUser() &&
                    <DealPageSuperUserSection/>
                }
            </Stack>
        </Box>
    )
}