import React, {useState} from 'react'
import Box from "@mui/material/Box";

import './CRMListDealsPage.scss'
import {useMediaQuery} from "react-responsive";
import {mediaQueryForMobile} from "../../HomePage/HomePage";
import {useModalContext} from "../../../contexts/ModalContext";
import {CRMListDealsPageHeader} from "./CRMListDealsPageHeader";
import {CRMListDealsList} from "./CRMListDealsList";
import {useCRMListDealsContext} from "../../../contexts/CRMListDealsContext";
import {MiniDealCard} from "./MiniDealCard";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

export const CRMListDealsPage = () => {
    const isTabletOrMobile = useMediaQuery({ query: mediaQueryForMobile })

    const { listDealsState, selectedDealIds, onlyOneSelection, initialSearchMoreOpen } = useCRMListDealsContext()
    const hasSelectedQuotes =  Boolean(selectedDealIds?.length)
    const [open, setOpen] = useState(!hasSelectedQuotes || initialSearchMoreOpen)
    const { isDealModalOpen } = useModalContext()

    if (!listDealsState) return null;

    return (
        <React.Fragment>
            <Box
                style={{
                    height: '98vh',
                    borderRadius: '8px',
                    display: isTabletOrMobile ? 'flex' : '',
                    padding: isTabletOrMobile ? '30px' : '60px 30px',
                    overflow: isTabletOrMobile ? 'auto' : 'hidden',
                    flexGrow: 2,
                    maxWidth: isTabletOrMobile ? '100%' : '',
                    flexDirection: isTabletOrMobile ? 'column' : '',
                    background: "#EFEFEF",
                    width: open ? '85vw' : '',
                    minWidth: '800px'
                }}
                id={"CRMListDealsPage"}
            >
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Stack
                        direction="row"
                        alignItems="center"
                    >
                        <Typography
                            sx={{
                                fontSize: '24px',
                                color: '#666'
                            }}
                            component="div"
                        >
                            Select Quote{ onlyOneSelection ? '': 's'}
                        </Typography>
                    </Stack>
                    {
                        !open &&
                        <span
                            onClick={() => setOpen(true)}
                            className={'link-text'}
                        >
                            Search more
                        </span>
                    }
                    {
                        open &&
                        <CRMListDealsPageHeader/>
                    }
                </Stack>
                <Divider className={'divider'} sx={{ marginBottom: '10px'}}/>
                <Stack
                    direction="row"
                    justifyContent="flex-start"
                    // alignItems="center"
                    style={{
                        overflow: 'scroll',
                        maxHeight: '820px',
                    }}
                >
                    <Stack
                        direction="column"
                        alignItems="flex-start"
                        className={'input-group'}
                        style={{
                            maxWidth: '325px',
                            overflow: 'scroll',
                            maxHeight: '820px',
                            flexWrap: 'nowrap',
                            padding: '10px 0',
                        }}
                    >
                        {
                            selectedDealIds.map((dealId) => (<MiniDealCard
                                    defaultChecked={true}
                                    key={dealId}
                                    dealId={dealId}
                                />)
                            )
                        }
                    </Stack>
                    <Divider
                        orientation="vertical"
                        flexItem
                        textAlign={"left"}
                        sx={{
                            marginRight: '10px',
                            fontSize: '10px'
                        }}
                    >
                        <KeyboardArrowLeftIcon/>
                        Selected
                    </Divider>

                    {
                        open &&
                        <Box
                            style={{
                                flex: 1,
                                padding: '10px 10px',
                                maxHeight: ''
                            }}
                        >
                            <CRMListDealsList
                                listDealsState={listDealsState}
                                selectedDealId={isDealModalOpen}
                            />
                        </Box>
                    }
                </Stack>
            </Box>
        </React.Fragment>
    )
}